
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";

import { GridCheckIcon } from "@mui/x-data-grid";
import { ClearIcon } from "@mui/x-date-pickers";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import ShLabel from "../../../../../components/theme/label/ShLabel";
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import Text from "../../../../../components/theme/text/Text";
import { useGetPriceIncreasesQuery } from "../../../../../newapi/financial/priceIncreaseSlice";

import { InvoicePriceIncreaseCard } from "../../../../../components/global/cards/financial/InvoicePriceIncreaseCard";
import EMCountingStrategyAmount from "../../../../../components/global/ModalsPopover/explainers/countingstrategy/EMCountingStrategyAmount";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import SidepanelLoader from "../../../../../components/theme/sidepanel2/SidepanelLoader";

const SpPriceIncreaseDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpPriceIncreaseDetails/SpPriceIncreaseDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return <></>
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,

};

const PriceIncreases = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended, setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();
    
    const ref = useRef();

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
        }
    },[]);

    const handleOpen = () => {
        setAddModalOpen(true);
    }
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    const [priceIncreaseId, setPriceIncreaseId] = useState();
    const priceIncreaseIdRef = useRef(priceIncreaseId);
    
    const getPriceIncrease = (params) => {
        if (!params) return;
    
        const newPriceIncreaseId = parseInt(params.row.id);
        // Only update state if the pickingJobListId has actually changed
        if (priceIncreaseIdRef.current !== newPriceIncreaseId) {
            
            if(!spLoading) {
                setSpLoading(true);
            }
            setPriceIncreaseId(newPriceIncreaseId);
            priceIncreaseIdRef.current = newPriceIncreaseId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const columns = [
        {
            field: "name",
            headerName: t("name"),
            flex:2,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
    
                return params.row.name;
            }
        },
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }

                let status = params.row.state;
                let labelcolor = "";
                let labeltext = "";
                switch(status){
                    case "active":
                        labelcolor = theme.palette.mode === "dark" ? "blue" : 'primary';
                        labeltext = t('active');
                        break;
                    case "completed":
                        labelcolor = "green"
                        labeltext = t('completed');
                        break;
                    default:
                        labelcolor = "orange"
                        labeltext = t('unknown');
                        break;
                }
              
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {labeltext}
                    </ShLabel>
                  );
              }
        },
        {field: "date", headerName: t("date"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            
            let date = new Date(params.row.date);
            return (
                <Text>
                    {date.toLocaleDateString(i18n.language)}
                </Text>
            );
        }},
        {
            field: "percentage",
            headerName: t("increase"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
    
                return `${params.row.percentage}%`;
            }
        },
        {
            field: "modifyCustomerPrices",
            headerName: t("edit_changed_prices"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (
                    <Box>
                        <Text variant={params.row.modifyCustomerPrices ? "green" : "red"}>
                            {params.row.modifyCustomerPrices ? (
                                <GridCheckIcon />
                            ) : (
                                <ClearIcon />
                            )}
                        </Text>
                    </Box>
                )
            }
        },
        {
            field: "products",
            headerName: t("products"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
    
                return params.row.invoiceProducts.length;
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <InvoicePriceIncreaseCard
                        onClick={() => getPriceIncrease(params)}
                        skeleton={params.row.skeleton}
                        {...params.row}
                    />
                    // <ShTextSkeleton/>
                )
            }
        }
    ];

    return (
        <Box height={1} ref={ref} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                explainer={<EMCountingStrategyAmount/>}
                content={useGetPriceIncreasesQuery}
                gridActions={<GridActions handleOpen={handleOpen}></GridActions>} 
                title={t('price_increases')}
                gridOptions={gridOptions} 
                columns={columns}
                rows={data}
                onRowClick={getPriceIncrease}
                sortModel={{field: columns[2].field,type:"DESC"}}
                >
            </ShDataGrid2>

            {spLoading && 
                <SpPriceIncreaseDetails
                    priceIncreaseId={priceIncreaseId}
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    // data={pickingJobData}
                />
            }
        </Box>
    );
}

export default PriceIncreases;