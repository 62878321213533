import { Box, useTheme } from "@mui/material";
import { forwardRef, useEffect, useRef, useState } from "react";
import Text from "../../../../../../components/theme/text/Text";
import { tokens } from "../../../../../../theme";

import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import MpAddWarehouse from "../../../../../../components/global/Modals/MAddWarehouse";
import ButtonGroup from "../../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../../components/theme/buttons/Shbutton";
import ShDataGrid2 from "../../../../../../components/theme/datagrid2/ShDataGrid2";
import SidepanelLoader from "../../../../../../components/theme/sidepanel2/SidepanelLoader";
import ShTextSkeleton from "../../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetWarehousesQuery } from "../../../../../../newapi/warehouse/warehouseApiSlice";

const SpWarehouseDetails = SidepanelLoader(() => import("../../../../../../components/global/Sidepanels/SpWarehouseDetails/SpWarehouseDetails"));

const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {},[props.open]);

    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("new_warehouse")}</Shbutton>
        </ButtonGroup>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    

};


const All = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended,setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    const [warehouseId, setwarehouseId] = useState();
    const warehouseIdRef = useRef(warehouseId);
    
    const getWarehouseDetails = (params) => {
        if (!params) return;
    
        const newwarehouseId = parseInt(params.row.id);
        // Only update state if the warehouseId has actually changed
        if (warehouseIdRef.current !== newwarehouseId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setwarehouseId(newwarehouseId);
            warehouseIdRef.current = newwarehouseId; // Update the ref to the new value
        }
        setIsExtended(true);
    };


    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    const columns = [
        {field: "name", 
        headerName: t("name"), 
        flex:1, 
        renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
            )
        }},
        {field: "street", 
        headerName: t("address"), 
        flex:2, 
        renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return `${cellValues.row.street} ${cellValues.row.house_nr}${cellValues.row.house_nr_extra}, ${cellValues.row.postal} ${cellValues.row.city}`
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // console.log(cellValues.row);
               // return (
                    // <AllCarriersCard {...cellValues.row} />
               // )
            }
        }
    ];
    
    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetWarehousesQuery}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t("overview")}
                gridOptions={gridOptions} 
                
                columns={columns} 
                sortModel={{field: columns[0].field,type:"ASC"}}
                onRowClick={getWarehouseDetails}>
            </ShDataGrid2>
            <MpAddWarehouse onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>

            {spLoading && 
                <SpWarehouseDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    warehouseId={warehouseId}
                /> 
            }

        </Box>
    )
});

export default All;