import { Box, InputBase, Typography, useTheme } from "@mui/material";

import cx from 'classnames';
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as StockVisual } from '../../../styles/svg/rmaportal/stockvisual.svg';
import { tokens } from "../../../theme";
import { HoverProvider } from "../HoverProvider";
import { Editable } from "../Editable";
import { portal_keys } from "../config";
import { editRegisterReturnMap } from "../componentMaps";


const EditRegisterReturn = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    const handleClick  = (editableKeyValue) => {
        // Find the key based on the value
        const key = Object.keys(editRegisterReturnMap).find(
            (key) => editRegisterReturnMap[key] === editableKeyValue
        );
        if(props.onClick) {
            props.onClick(key);
        }
    }

    return (
        <HoverProvider>
            <Box
            component={"main"}
                sx={{
                    height:1,
                    // minHeight:"calc(100%)"
                    zIndex:1,
                }}
            >
                <Editable onClick={() => handleClick(editRegisterReturnMap.section)} radius={4}>
                    <Box
                        component={"section"}
                        sx={{
                            borderTopLeftRadius:16,
                            borderTopRightRadius:16,
                            background:"rgba(65,128,246,.10)",
                            background: "radial-gradient(circle, rgba(65,128,246,0.25) 0%, rgba(65,128,246,0.10) 100%)",
                            // background: "radial-gradient(circle, orange 0%, red 100%)",
                            width:1,
                            minHeight:"800px",
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                            height:"75vh",
                            height:1,
                            position:'relative',
                            overflow:"hidden",

                            ".stock-visual" : {
                                position:'absolute',
                                bottom:"0",
                                width:1,
                            }
                        }}
                    >

                        <Box
                            sx={{
                                width:500,
                            }}
                        >

                            <Editable  onClick={() => handleClick(editRegisterReturnMap.header)} radius={8}>   
                                <Typography
                                    variant="h1"
                                    sx={{
                                        fontSize:"2.75em",
                                        fontWeight:"bold",
                                        color:"#31415f",
                                        textAlign:"center",
                                        mb:3,
                                    }}
                                >
                                    {t("create_return")}
                                </Typography>
                            </Editable>

                            <Editable  onClick={() => handleClick(editRegisterReturnMap.orderNumber)} radius={6}>   
                                <Box 
                                    display="flex"
                                    sx={{
                                        width:1,
                                        borderRadius:6,
                                        display:"flex",
                                        alignItems:"center",
                                        background:"rgba(65,128,246,.15)",
                                        border:"1px solid rgba(65,128,246,.15)",
                                        height:48,
                                        pl:1,
                                        "&.error": {
                                            background:colors.red[100],
                                            border: `1px solid ${colors.red[400]}`,
                                            color: colors.red[400]
                                        },
                                        "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active" : {
                                            backgroundClip:"#fff",
                                            "webkit-text-fill-color":"#fff",
                                            transition: "background-color 5000s ease-in-out 0s",
                                            boxShadow:"inset 0 0 20px 20px #23232329",
                                            WebkitTextFillColor: "white",
                                            fontSize:16
                                        },
                                        "@media screen and (max-width: 64em)" : {
                                        },
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:1,
                                        }
                                    }}

                                    className={cx({
                                        // 'error': !!touched.ordernumber && !!errors.ordernumber
                                    })}
                                >
                                    <Editable  onClick={() => handleClick(editRegisterReturnMap.orderNumberText)} radius={4}>   
                                        <Box
                                            className={cx({
                                                // 'error': !!touched.ordernumber && !!errors.ordernumber
                                            })}
                                            sx={{
                                                height:32,
                                                width:142,
                                                px:2,
                                                borderRadius:4,
                                                textAlign:"center",
                                                fontWeight:"bold",
                                                color: "rgb(65,128,246)",
                                                background:colors.primary[100],
                                                display:"flex",
                                                alignItems:"center",
                                                justifyContent:"center",

                                                "&.error": {
                                                    background:colors.red[100],
                                                    color: colors.red[400]
                                                },
                                            }}
                                        >
                                            {t("ordernumber")}
                                        </Box>
                                    </Editable>  
                                    <Editable  onClick={() => handleClick(editRegisterReturnMap.orderNumberInput)} radius={4}>    
                                        <InputBase 
                                            autoFocus
                                            type={"text"}
                                            sx={{
                                                pl:1.5,
                                                flex:1,
                                                color:"#31415f",
                                                fontSize:16,
                                                fontWeight:"500"
                                            }} 
                                            placeholder="#" 
                                            
                                        />
                                    </Editable> 
                                </Box>
                            </Editable>   

                            <Editable  onClick={() => handleClick(editRegisterReturnMap.email)} radius={8}>   
                                <Box 
                                    display="flex"
                                    sx={{
                                        mt:2,
                                        width:1,
                                        borderRadius:6,
                                        display:"flex",
                                        alignItems:"center",
                                        background:"rgba(65,128,246,.15)",
                                        border:"1px solid rgba(65,128,246,.15)",
                                        height:48,
                                        pl:1,
                                        "&.error": {
                                            background:colors.red[100],
                                            border: `1px solid ${colors.red[400]}`,
                                            color: colors.red[400]
                                        },
                                        "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active" : {
                                            backgroundClip:"#fff",
                                            "webkit-text-fill-color":"#fff",
                                            transition: "background-color 5000s ease-in-out 0s",
                                            boxShadow:"inset 0 0 20px 20px #23232329",
                                            WebkitTextFillColor: "white",
                                            fontSize:16
                                        },
                                        "@media screen and (max-width: 64em)" : {
                                        },
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:1,
                                        }
                                    }}

                                    className={cx({
                                        // 'error': !!touched.email && !!errors.email
                                    })}
                                >
                                    <Editable  onClick={() => handleClick(editRegisterReturnMap.emailText)} radius={6}>   
                                        <Box
                                            className={cx({
                                                // 'error': !!touched.email && !!errors.email
                                            })}
                                            sx={{
                                                height:32,
                                                width:142,
                                                
                                                px:2,
                                                borderRadius:4,
                                                textAlign:"center",
                                                fontWeight:"bold",
                                                color: "rgb(65,128,246)",
                                                background:colors.primary[100],
                                                display:"flex",
                                                alignItems:"center",
                                                justifyContent:"center",
                                                "&.error": {
                                                    background:colors.red[100],
                                                    color: colors.red[400]
                                                },
                                            }}
                                        >
                                            {t("email")}
                                        </Box>
                                    </Editable>
                                    <Editable  onClick={() => handleClick(editRegisterReturnMap.emailInput)} radius={6}>   
                                        <InputBase 
                                            type={"email"}
                                            sx={{
                                                pl:1.5,
                                                flex:1,
                                                color:"#31415f",
                                                fontSize:16,
                                                fontWeight:"500"
                                            }} 
                                            placeholder={t('email')} 
                                            
                                        />
                                    </Editable>
                                </Box>
                            </Editable>   
                            
                            <Box
                                sx={{
                                    display:"flex",
                                    justifyContent:"flex-end",
                                    mt:2,
                                    width:1,
                                    "@media screen and (max-width: 47.9375em)" : {
                                        // marginTop:4
                                    }

                                }}
                            >
                                <Editable onClick={() => handleClick(editRegisterReturnMap.requestReturnButton)} radius={8} width={1}>   
                                    
                                    <Box
                                // onClick={() => switchInnerEnvironment(nav[selectedEnvironmentIndex])}
                                        sx={{
                                            display:"flex",
                                            alignItems:"center",
                                            justifyContent:"center",
                                            color:"white",
                                            fontWeight:"500",
                                            fontSize:16,
                                            // justifyContent:"flex-start",
                                            height:"48px",
                                            width:"48px",
                                            alignItems:"center",
                                            // pl:2,
                                            // background:"rgba(255,255,255,.15)",
                                            background:"rgba(65,128,246,1)",
                                            borderRadius:6,
                                            flexShrink:0,
                                            width:1,
                                            transition:"250ms",
                                            // "&:hover" : {
                                            //     background:"#0057FA",
                                            //     transition:"250ms"
                                            // },    
                                            
                                        }}
                                    >
                                        {t("request_return")}
                                        {/* <ArrowBackIcon fontSize="small"/> */}
                                    </Box>
                                </Editable>
                                {/* <Button type="submit" color="primary" variant="contained">
                                    Create new user
                                </Button> */}
                            </Box>

                            <Typography
                                sx={{
                                    mt:3,
                                    textAlign:"center",
                                    fontWeight:"500",
                                    color:"#31415f",
                                }}
                            >
                                {t("retour_check_status")}
                            </Typography>
                        </Box>

                        <StockVisual className="stock-visual"/>
                    </Box>
                </Editable>
            </Box>
        </HoverProvider>
    )
}

export default EditRegisterReturn;