import { editableElements, editableSettings, page_keys, portal_keys } from "./config";


export const editRegisterReturnMap = {
    section: portal_keys.SECTION,

    header: portal_keys.HEADER_TEXT,

    orderNumber: portal_keys.INPUT_CONTAINER,
    orderNumberText: portal_keys.INPUT_CONTAINER_TEXT,
    orderNumberInput: portal_keys.INPUT_CONTAINER_INPUT,

    email: portal_keys.INPUT_CONTAINER,
    emailText: portal_keys.INPUT_CONTAINER_TEXT,
    emailInput: portal_keys.INPUT_CONTAINER_INPUT,

    requestReturnButton: portal_keys.BUTTON,
}

export const defaultData = {
    savedColors: Array.from({ length: 12 }, () => editableElements.BACKGROUNDCOLOR),
}

export const masterStyleMap = {
    [page_keys.EDITREGISTERRETURN]: editRegisterReturnMap,
    [page_keys.DEFAULTDATA]: defaultData
};

export const createMap = (givenMap) => {
    const map = {};

    Object.keys(givenMap).forEach(key => {
        const portalKey = givenMap[key];
        const editableElements = editableSettings[portalKey]?.editable || [];
        const extraClasses = editableSettings[portalKey]?.extraClasses || {};

        const portalValueMap = {
            // Generate editableValues for the elements (set default empty values)
            editableValues: editableElements.reduce((acc, element) => {
                acc[element] = '';  // Set default empty value for each editable element
                return acc;
            }, {}),
        };

        // Only add extraClasses to the map if they exist and are non-empty
        if (Object.keys(extraClasses).length > 0) {
            portalValueMap.extraClasses = Object.entries(extraClasses).reduce((acc, [extraClassKey, editableElementsForClass]) => {
                // For each extra class, assign a default state for each editable element
                acc[extraClassKey] = editableElementsForClass.reduce((classAcc, element) => {
                    classAcc[element] = '';  // Default state for each editable element
                    return classAcc;
                }, {});
                
                // Optionally add 'visible' key to each extra class if needed
                acc[extraClassKey]['visible'] = false;
                return acc;
            }, {});
        }

        // Assign the final object to the map
        map[key] = portalValueMap;
    });

    return map;
}
export const transformStyleMapIfNeeded = (data) => {
    let updatedData = {};
    // through all the keys in masterMap
    for (let mapKey in masterStyleMap) {
        // If the mapKey exists in data, keep the data, else initialize it with createMap()
        if (data.hasOwnProperty(mapKey)) {
            updatedData[mapKey] = data[mapKey];
        } else {
            updatedData[mapKey] = createMap(masterStyleMap[mapKey]); // Initialize with createMap if not found
        }
    }
  
    // Return the transformed data object, now with all necessary keys from masterMap
    return updatedData;
  };
