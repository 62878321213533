import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
// import { tokens } from "../../../../theme";
import { tokens } from "../../../../../../theme";

import Subheading from "../../../../../../components/theme/text/Subheading";

import { useTranslation } from "react-i18next";
import Shbutton from "../../../../../../components/theme/buttons/Shbutton";

import { editRegisterReturnMap } from "../../../../../../components/portalbuilder/componentMaps";
import { editableElements } from "../../../../../../components/portalbuilder/config";
import { SHPBBackgroundColor } from "../../../../../../components/portalbuilder/Edittors/SHPBBackgroundColor";
import { SHPBBorder } from "../../../../../../components/portalbuilder/Edittors/SHPBBorder";
import { SHPBColor } from "../../../../../../components/portalbuilder/Edittors/SHPBColor";
import { SHPBPaddingX } from "../../../../../../components/portalbuilder/Edittors/SHPBPaddingX";
import { SHPBSpiral } from "../../../../../../components/portalbuilder/Edittors/SHPBSpiral";
import MPortalPreview from "../../../../../../components/portalbuilder/MPortalPreview";
import ReturnPortalViewPort from "../../../../../../components/portalbuilder/ViewPort";
import EditRegisterReturn from "../../../../../../components/portalbuilder/views/EditRegisterReturn";
import ButtonGroup from "../../../../../../components/theme/buttons/ButtonGroup";
import { useUpdatePortalstyleMutation } from "../../../../../../newapi/global/relation/relationSlice";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../../utils/toasts";


const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <ButtonGroup version={2}>
            <Shbutton
                isLoading={props.isLoading}
                className={"group-btn"}
                onClick={props.action}
                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                variant="contained"
                // endIcon={<SaveIcon />}
            >
                {t('save')}
            </Shbutton>
        </ButtonGroup>
    );
};


const Home = (props) => {
    const { t } = useTranslation();
    const apiRef = props.apiRef;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            mobileActions: <GridActions/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }
    const handleClose = () => {
        setAddModalOpen(false);
    };

    const [type, setType] = useState(editRegisterReturnMap.section);

    const [valueMap, setValueMap] = useState(props.data);
      
    useEffect(() => {
        setValueMap(props.data);
    },[props.data]);


  

    // Function to toggle the visibility of an extra class
    const handleCheckboxChange = (extraClassKey) => {
        setValueMap(prevMap => {
            const updatedMap = { ...prevMap };

            // Set only the selected extra class to 'visible: true', and others to 'false'
            const extraClasses = updatedMap[type]?.extraClasses;
            Object.keys(extraClasses).forEach(classKey => {
                if (classKey === extraClassKey) {
                    extraClasses[classKey].visible = !extraClasses[classKey].visible;  // Toggle visibility
                } else {
                    extraClasses[classKey].visible = false; // Hide other extra classes
                }
            });

            return updatedMap;
        });
    };

    const updateValue = (type, item, value) => {
        setValueMap(prevMap => {
          // Ensure we copy the previous state deeply to maintain immutability
          const updatedMap = { ...prevMap };
      
          // Check if the 'type' exists in the updated map, if not initialize it
          updatedMap[type] = {
            ...updatedMap[type], 
            editableValues: {
              ...updatedMap[type]?.editableValues, 
              [item]: value
            }
          };
      
          return updatedMap;
        });
    };

    useEffect(() => {
        console.log(valueMap);
    }, [valueMap])

    const [updatePortalstyle] = useUpdatePortalstyleMutation();
    const saveDesign = () => {
        try{
            updatePortalstyle({
                style: valueMap
            });
            showToastMessageUpdateRequest(t("design_saved"));
        }catch(error){
            showToastMessageRequestError(t("save_design_error"),t,error);
        }

    }


    return (
        <Box
            sx={{
                height: 'calc(100vh - 112px)', // Ensure the parent has a fixed height
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                },
            }}
        >
            <Box
                sx={{
                    height: '100%', // Ensure the parent has a fixed height
                    overflow: "hidden", // Prevent the parent from scrolling
                }}
            >
                <Box
                    sx={{
                        height: '100%', // Ensure the parent has a fixed height
                        overflow: "hidden", // Enable scrolling on the inner container
                        // mt: 4,
                        // mx: 4,
                        background: "blue",
                        height: '100%', // Ensure the inner container takes full height
                        display:"flex",
                        background:theme.palette.mode === "dark" ? colors.grey[300]: colors.bg['tertiary'],
                        height:1,
                        borderTopLeftRadius:24,
                        borderTopRightRadius:24,
                        boxShadow:"0 0 19px rgba(49,65,95,.25)",
                        border: `1px solid ${theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200]}`,
                        borderBottom:"none",
                        "@media screen and (max-width: 47.9375em) " : {
                            flexDirection:"column",
                            boxShadow:"none",
                            border:"none",
                            borderRadius:0,
                        }
                    }}
                >
                    {/* Canvas */}
                    <ReturnPortalViewPort
                        addModal={addModal}
                        setAddModalOpen={setAddModalOpen}
                        open={false}
                    >
                        <EditRegisterReturn onClick={setType}/>
                    </ReturnPortalViewPort>
                  
                    {/* Editting stuff */}
                    <Box
                        sx={{
                            overflow:"hidden",
                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
                            width:300,
                            display:"flex",
                            flexDirection:"column",
                            boxShadow: "0 0 19px rgba(49,65,95,.25)",
                            pt:3,
                            "@media screen and (max-width: 47.9375em) " : {
                                mt:3,
                                flex:1,
                                width:"unset",
                                borderTopLeftRadius:24,
                                borderTopRightRadius:24,
                            }
                        }}
                    >
                        <Box
                            sx={{
                                pl:3,
                            }}
                        >
                            <Subheading>
                                Aanpassen
                            </Subheading>
                        </Box>
                        <Box
                            sx={{
                                height:"calc(100% - 116px)",
                                overflow:"auto",
                            }}
                        >
                            <Box sx={{ height: 2000 }}>

                                {valueMap[type]?.extraClasses && Object.entries(valueMap[type]?.extraClasses).length > 0 && 
                                    <Box sx={{mt:3, background:"lightorange"}}>
                                        {Object.entries(valueMap[type]?.extraClasses).map(([extraClassKey, editableElementsForClass]) => (
                                            <Box key={extraClassKey} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                <input 
                                                    type="checkbox" 
                                                    checked={valueMap[type]?.extraClasses?.[extraClassKey]?.visible || false} 
                                                    onChange={() => handleCheckboxChange(extraClassKey)}
                                                />
                                                <span>{extraClassKey}</span>
                                            </Box>
                                        ))}
                                    </Box>
                                }


                                <Box sx={{
                                        mt:3,
                                        display:"flex",
                                        flexDirection:"column",
                                        gap:1
                                    }}
                                >
                                    {/* Check if any extra class is visible */}
                                    {Object.values(valueMap[type]?.extraClasses || {}).some(extraClass => extraClass.visible) ? (
                                        // Render components based on the visible extra class
                                        Object.entries(valueMap[type]?.extraClasses || {}).map(([extraClassKey, extraClassValues]) => {
                                            if (extraClassValues.visible) {
                                                return (
                                                    <Box>
                                                        <Subheading>{extraClassKey}</Subheading>
                                                        {Object.keys(extraClassValues).map((item, index) => (
                                                            <Box key={index}>
                                                                {item === editableElements.SPIRAL && <SHPBSpiral />}
                                                                {item === editableElements.BACKGROUNDCOLOR && <SHPBBackgroundColor />}
                                                                {item === editableElements.COLOR && <SHPBColor />}
                                                                {item === editableElements.BORDER && <SHPBBorder />}
                                                                {item === editableElements.PADDINGX && <SHPBPaddingX />}
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                )
                                                
                                            }
                                            return null;
                                        })
                                    ) : (
                                        // Default case: Render editable values
                                        Object.keys(valueMap[type]?.editableValues || {}).map((item, index) => (
                                            <Box key={index}>
                                                {console.log(item, valueMap[type].editableValues[item])}
                                                {/* {console.log(item)} */}
                                                {item === editableElements.SPIRAL && <SHPBSpiral />}
                                                {item === editableElements.BACKGROUNDCOLOR && <SHPBBackgroundColor />}
                                                {item === editableElements.COLOR && 
                                                    <SHPBColor 
                                                    // value={valueMap[type].editableValues[item]} 
                                                    value={"#31415f"} 
                                                    setValue={(value) => updateValue(type,item,value)}
                                                    />
                                                }
                                                {item === editableElements.BORDER && <SHPBBorder />}
                                                {item === editableElements.PADDINGX && <SHPBPaddingX />}
                                            </Box>
                                        ))
                                    )}
                                </Box>

                            </Box>
                        </Box>
                        <Box
                            sx={{
                                height:64,
                                px:3,
                                "@media screen and (max-width: 47.9375em) " : {
                                    display:"none",
                                }
                            }}
                        >
                            {/* <ButtonGroup */}
                            <Shbutton className={"group-btn"} color={"green"}
                                variant="contained" 
                                block
                             onClick={() => saveDesign()}
                            >
                                {t("save")}
                            </Shbutton>
                        </Box>
                    </Box>
                </Box>
            </Box>
                
            <MPortalPreview addModal={addModal} setAddModalOpen={setAddModalOpen}  onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}>
                <EditRegisterReturn/>
            </MPortalPreview>
        </Box>
    )
};

export default Home;