import { Box, Popover, Slide, useTheme } from "@mui/material"
import Grow from '@mui/material/Grow';
import { forwardRef, useEffect, useState } from "react";

// import { ReactComponent as ArrowAlt } from '../../styles/svg/arrow_alt.svg';
import { ReactComponent as Triangle } from "../../../styles/svg/triangle.svg";
import { tokens } from "../../../theme";

const SlideUpTransition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" timeout={500} ref={ref} {...props}  />;
});

const GrowTransition = forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} timeout={200}></Grow>
})

const PopoverModal = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [triangle, setTriangle] = useState(props.triangle ?? 
        {
            position:"right",
            color: theme.palette.mode === "dark" ? colors.grey[300] : "#fff"
        }
    );
    const [triangleRightOffset, setTriangleRightOffset] = useState(0);

    useEffect(() => {
        if(props.anchorEl !== null) {
            if(triangle.position === "right") {
                setTriangleRightOffset(`${props.anchorEl.clientWidth / 2}px`);
            } else if (triangle.position === "center") {
                setTriangleRightOffset(`calc(50% - 16px)`)
            }
        }

        setTriangle(props.triangle ?? 
            {
                position:"right",
                color: theme.palette.mode === "dark" ? colors.grey[300] : "#fff"
            }
        )
    },[props.anchorEl, props.triangle,theme.palette.mode])
   
    const anchorOrigin = {
        vertical: `${typeof props.anchorOrigin !== 'undefined' ? props.anchorOrigin.vertical ?? 'bottom' : 'bottom'}`,
        horizontal: `${typeof props.anchorOrigin !== 'undefined' ? props.anchorOrigin.horizontal ?? 'center' : 'center'}`,
    };
    
    const transformOrigin = {
        vertical: `${typeof props.transformOrigin !== 'undefined' ? props.transformOrigin.vertical ?? 'top' : 'top'}`,
        horizontal: `${typeof props.transformOrigin !== 'undefined' ? props.transformOrigin.horizontal ?? 'center' : 'center'}`,
    }


    
    return (
        <Popover 
            disableRestoreFocus={props.disableRestoreFocus ?? null}
            id={props.id}
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
            onMouseEnter={props.onMouseEnter ?? null}
            slotProps={{paper: {
                sx: {
                    pointerEvents:"auto"
                }
            }}}
            TransitionComponent={window.innerWidth < 768 ? SlideUpTransition : GrowTransition}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            sx={{
                // position:"relative",
                top:anchorOrigin.vertical === "bottom" ? "24px" : null,
                // marginBottom:anchorOrigin.vertical === "bottom" ? "24px" : null,
                bottom: anchorOrigin.vertical === "top" ? "24px" : null,
                borderRadius:8,
                outline:"none",
                "& .MuiPaper-root" : {
                    background:`transparent`,
                    boxShadow: "none",
                    overflow:"visible",
                    borderRadius:4,
                    outline:"none",
                },

                "& .popover-triangle" : {
                    position:"absolute",
                    right:triangleRightOffset,
                    top:anchorOrigin.vertical === "bottom" ? "-15px" : null,
                    bottom:anchorOrigin.vertical === "top" ? "-15px" : null,
                    transform:anchorOrigin.vertical === "top" ? "rotate(180deg)" : null,
                    // botto:-15,
                    width:32,
                    height:16,
                    zIndex:200,
                    "& *" : {
                        fill:triangle.color,
                    },
                },

                "@media screen and (max-width:48em)" : {
                    // top:"unset",
                    "& .popover-triangle" : {
                        display:"none"
                    },
                    "& .MuiModal-backdrop" : {
                        background:`${theme.palette.mode === "dark" ? "rgba(14,14,17,.85)": "rgba(46,65,95,.75)"}`,
                        backdropFilter:"blur(4px)",
                    },
                    "& .MuiPaper-root" : {
                        left:"0 !important",
                        top:"unset !important",
                        bottom:0,
                        maxWidth:"unset",
                        width:1,
                        transformOrigin:"0px 300px !important",
                        borderBottomRightRadius:0,
                        borderBottomLeftRadius:0
                    }
                },
                "@media screen and (min-width:63.9375em)" : {
                    pointerEvents: props.hover ? "none" : null,
                }
            }}
        >
            
            <Triangle 
                className="popover-triangle"
            ></Triangle>
            <Box
                sx={{
                    position:"relative",
                    background:`transparent`,
                    boxShadow: "0px 2px 8px rgba(49, 65, 95, 0.25)",
                    borderRadius:4,
                    overflow:"hidden",
                    "@media screen and (max-width:48em)" : {
                        borderBottomRightRadius:0,
                        borderBottomLeftRadius:0,
                        "&:before" : {
                            display:"none"
                        }
                    }
                }}
            >
                {props.children}
            </Box>
        </Popover>
    )
}

export default PopoverModal;