import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Text from "../../../../../../components/theme/text/Text";
import { tokens } from "../../../../../../theme";

import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import MAddWarehouseLocationType from "../../../../../../components/global/Modals/MAddWarehouseLocationType";
import ButtonGroup from "../../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../../components/theme/buttons/Shbutton";
import ShDataGrid2 from "../../../../../../components/theme/datagrid2/ShDataGrid2";
import SidepanelLoader from "../../../../../../components/theme/sidepanel2/SidepanelLoader";
import ShTextSkeleton from "../../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetWarehouseLocationTypesQuery } from "../../../../../../newapi/warehouse/warehouseLocationTypeSlice";

const SpWarehouseLocationTypeDetails = SidepanelLoader(() => import("../../../../../../components/global/Sidepanels/SpWarehouseLocationTypeDetails/SpWarehouseLocationTypeDetails"));

const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {},[props.open]);

    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("new_warehouse_location_type")}</Shbutton>
        </ButtonGroup>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    

};

const WarehouseLocationTypes = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended,setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

    const [warehouseLocationTypeId, setwarehouseLocationTypeId] = useState();
    const warehouseLocationTypeIdRef = useRef(warehouseLocationTypeId);
    
    const getWarehouseLocationTypeDetails = (params) => {
        if (!params) return;
    
        const newwarehouseLocationTypeId = parseInt(params.row.id);
        // Only update state if the warehouseLocationTypeId has actually changed
        if (warehouseLocationTypeIdRef.current !== newwarehouseLocationTypeId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setwarehouseLocationTypeId(newwarehouseLocationTypeId);
            warehouseLocationTypeIdRef.current = newwarehouseLocationTypeId; // Update the ref to the new value
        }
        setIsExtended(true);
    };
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const columns = [
        {field: "description", 
        headerName: t("description"), 
        flex:2, 
        renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
            )
        }},
        {field: "type", 
        headerName: t("type"), 
        flex:1, 
        renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return t(cellValues.row.type)
        }},
        {field: "dimensions", 
        headerName: t("dimensions_lwh"), 
        flex:1, 
        renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return `${cellValues.row.length} x ${cellValues.row.width} x ${cellValues.row.height}`
        }},
        {field: "maxWeight", 
        headerName: `${t("max_weight")} (${t("kg")})`, 
        flex:1, 
        renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return `${cellValues.row.maxWeight} kg`
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // console.log(cellValues.row);
               // return (
                    // <AllCarriersCard {...cellValues.row} />
               // )
            }
        }
    ];
    
    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetWarehouseLocationTypesQuery}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t('overview')} 
                gridOptions={gridOptions} 
                
                sortModel={{field: columns[0].field,type:"ASC"}}
                columns={columns} 
                onRowClick={getWarehouseLocationTypeDetails}>
            </ShDataGrid2>
            <MAddWarehouseLocationType onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>

            {spLoading && 
                <SpWarehouseLocationTypeDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    warehouseLocationTypeId={warehouseLocationTypeId}
                    // data={warehouseLocationTypeData}  
                /> 
            }
        </Box>
    )
};

export default WarehouseLocationTypes;