import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ShTextSkeleton from "../../../../components/theme/skeleton/ShTextSkeleton";
import Text from "../../../../components/theme/text/Text";
import { tokens } from "../../../../theme";

import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import { WarehouseRelationsCard } from "../../../../components/global/cards/warehouserelations/WarehouseRelationsCard";
import MpAddRelation from "../../../../components/global/ModalsProgression/MpAddRelation/MpAddRelation";
import ButtonGroup from "../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../components/theme/buttons/Shbutton";
import ShDataGrid2 from "../../../../components/theme/datagrid2/ShDataGrid2";
import ShDoubleTextSkeleton from "../../../../components/theme/skeleton/ShDoubleTextSkeleton";
import { useGetRelationsQuery } from "../../../../newapi/global/relation/relationSlice";
import SidepanelLoader from "../../../../components/theme/sidepanel2/SidepanelLoader";

const SpRelationDetails = SidepanelLoader(() => import("../../../../components/global/Sidepanels/SpRelationDetails/SpRelationDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('new_relation')}
            </Shbutton>
        </ButtonGroup>
    );
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    

};

const All = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended,setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions
            // apiRef: props.apiRef
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);


    const [relationId, setRelationId] = useState();
    const relationIdRef = useRef(relationId);
    
    const getRelationDetails = (params) => {
        if (!params) return;
    
        const newrelationId = parseInt(params.row.id);
        // Only update state if the relationId has actually changed
        if (relationIdRef.current !== newrelationId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setRelationId(newrelationId);
            relationIdRef.current = newrelationId; // Update the ref to the new value
        }
        setIsExtended(true);
    };
    
    const columns = [
        {
            field: "name", 
            headerName: t("relation"), 
            flex:2, 
            cellClassName: "relation", 
            renderCell : (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (
                    <Text semibold>{cellValues.row.name}</Text>
                )
            }
        },
        {
            field: "street",
            headerName: t("address"),
            flex:1,
            renderCell : (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShDoubleTextSkeleton/>
                }

                const street = cellValues.row.street ? cellValues.row.street : "";
                const house = cellValues.row.house_nr ? cellValues.row.house_nr : "";
                const extra = cellValues.row.house_nr_extra ? cellValues.row.house_nr_extra : "";
                const postal = cellValues.row.postal ? cellValues.row.postal : "";
                const city = cellValues.row.city ? cellValues.row.city : "";

                return <Text>{street} {house}{extra}<br/> {postal}, {city}</Text>
            },
        },
        {
            field: "phone",
            headerName: t("phone"),
            flex:1,
            renderCell : (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return cellValues.row.phone
            },
        },
        {
            field: "email",
            headerName: t("email"),
            flex:1,
            renderCell : (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return cellValues.row.email
            },
        },
        {
            field: "website",
            headerName: t("website"),
            flex:1,
            renderCell : (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                
                return cellValues.row.website
            },
        },
        {
            field: "webshopcount",
            headerName: t("channels"),
            flex:1,
            sortable:false,
            renderCell : (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (cellValues.row.webshopCredentials ? cellValues.row.webshopCredentials.length : 0)
            },
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (
                    <WarehouseRelationsCard
                        onClick={() => getRelationDetails(cellValues)}
                        skeleton={cellValues.row.skeleton}
                        {...cellValues.row}
                    />
                );
                // console.log(cellValues.row);
               // return (
                    // <AllCarriersCard {...cellValues.row} />
               // )
            }
        }
    ];

    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetRelationsQuery}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t("overview")}
                gridOptions={gridOptions} 
                columns={columns} 
                sortModel={{field: columns[0].field,type:"ASC"}}
                
                onRowClick={getRelationDetails}
                >
            </ShDataGrid2>

            {spLoading && 
                <SpRelationDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    relationId={relationId}
                    // data={relationData}                
                /> 
            }

            <MpAddRelation onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
        </Box>
    )
};

export default All;