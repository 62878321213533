import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Shbutton from "../../../../components/theme/buttons/Shbutton";

import { tokens } from "../../../../theme";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import Text from "../../../../components/theme/text/Text";
import { useGetProductsQuery } from "../../../../newapi/warehouse/productSlice";
import ButtonGroup from "../../../../components/theme/buttons/ButtonGroup";
import ShTextSkeleton from "../../../../components/theme/skeleton/ShTextSkeleton";
import RelationProductCard from "../../../../components/global/cards/products/relation/RelationProductCard";
import ShDataGrid2 from "../../../../components/theme/datagrid2/ShDataGrid2";
import MpAddProduct from "../../../../components/global/ModalsProgression/MpAddProduct/MpAddProduct";
import ShCopyButton from "../../../../components/theme/ShCopy";

const GridActions = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t("new_product")}
            </Shbutton>
        </ButtonGroup>
    );
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,

};

const ProductOverview = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [config, setConfig] = useState({isVirtual: false});
    const [isExtended, setIsExtended] = useState(false);

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
            mobileActions: <GridActions handleOpen={handleOpen}></GridActions>,
        });

        return () => {
            
        }
    },[]);

    const handleOpen = () => {
        setAddModalOpen(true);
    }

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    const columns = [
        {
            field: "sku",
            headerName: t("sku"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (
                    <Box sx={{
                        minWidth:"100%",
                        height:"100%",
                        display:"flex",
                        alignItems:"center",
                        position:"relative",
                        whiteSpace:"nowrap",
                        wordBreak:"keep-all",
                        textOverflow:"ellipsis",
                        overflow:"hidden",
                        ".copyel": {
                            opacity:0,
                        },
                        "&:hover .copyel": {
                            opacity:1,
                        }
                    }}>
                        <Text noBreak>{params.row.sku}</Text>
                        <ShCopyButton
                            styles={{button:{
                                background:theme.palette.mode === "dark" ? colors.grey[600] :  colors.grey[200],
                                color: colors.txt["primary"],
                                "&:hover" : {
                                    background:theme.palette.mode === "dark" ? colors.grey[500] :colors.grey[300],

                                }
                            }}} 
                            sx={{position:"absolute",right:0,zIndex:3000}} 
                            className={"copyel"} 
                            closeAfter={1500} 
                            value={params.row.sku}
                        ></ShCopyButton>
                    </Box>
                );
            }
        },
        {
            field: "description",
            headerName: t("description"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.description
            },
        },
        {
            field: "barcode",
            headerName: t("barcode"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (
                    <Box sx={{
                        minWidth:"100%",
                        height:"100%",
                        display:"flex",
                        alignItems:"center",
                        position:"relative",
                        whiteSpace:"nowrap",
                        wordBreak:"keep-all",
                        textOverflow:"ellipsis",
                        overflow:"hidden",
                        ".copyel": {
                            opacity:0,
                        },
                        "&:hover .copyel": {
                            opacity:1,
                        }
                    }}>
                        <Text noBreak>{params.row.barcode}</Text>
                        <ShCopyButton
                            styles={{button:{
                                background:theme.palette.mode === "dark" ? colors.grey[600] :  colors.grey[200],
                                color: colors.txt["primary"],
                                "&:hover" : {
                                    background:theme.palette.mode === "dark" ? colors.grey[500] :colors.grey[300],

                                }
                            }}} 
                            sx={{position:"absolute",right:0,zIndex:3000}} 
                            className={"copyel"} 
                            closeAfter={1500} 
                            value={params.row.barcode}
                        ></ShCopyButton>
                    </Box>
                )
            },
        },
        {
            field: "secondary_sku",
            headerName: t("secondary_sku"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.secondary_sku
            },
        },
        {
            field: "stock_quantity",
            headerName: t("stock_quantity"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (params.row.stock && params.row.stock.total_quantity ? params.row.stock.total_quantity : 0);
            },
        },
        {
            field: "stock_available",
            headerName: t("stock_available"),
            flex:1,
            sortable:false,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (params.row.stock && params.row.stock.total_available ? params.row.stock.total_available : 0);
            },
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // console.log(cellValues.row);
                return (
                    
                    <RelationProductCard
                        skeleton={cellValues.row.skeleton}
                        {...cellValues.row}
                    />
                    // <Box
                    //     sx={{
                    //         background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                    //         paddingX:2,
                    //         paddingY:1.5,
                    //         borderRadius:2
                    //     }}
                    // >

                    // </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetProductsQuery}
                config={config}
                gridActions={<GridActions handleOpen={handleOpen}></GridActions>} 
                title={t('overview')} 
                gridOptions={gridOptions} 
                columns={columns}
                sortModel={{field: columns[0].field,type:"ASC"}}
                rows={data}>
            </ShDataGrid2>
            <MpAddProduct onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
        </Box>
    );
}
export default ProductOverview;