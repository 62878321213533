import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";


// import SPOrderDetails from "../../../../components/global/Sidepanels/SpOrderDetails/SpOrderDetails";
import { useTranslation } from 'react-i18next';
import TabView3 from "../../../../components/theme/TabView3/TabView3";
import InboundInboundLines from "./views/InboundLines";
import InboundsView from "./views/Inbounds";
import InboundReturns from "./views/Returns";
import { warehouseInboundInboundsConfig } from "./warehouseInboundInboundsConfig";
import ShDateRangePicker from "../../../../components/theme/ShDateRangePicker";
import AreaChart from "../../../../components/theme/charts/AreaChart";
import { ReactComponent as EuroLogo } from '../../../../styles/svg/euro.svg';
import { ReactComponent as OrdersLogo } from '../../../../styles/svg/orders.svg';
import { ReactComponent as ProductLogo } from '../../../../styles/svg/shirt-product.svg';
import { formatPrice, removeTrailingZeros } from "../../../../utils/helpers";
import ShDoubleTextSkeleton from "../../../../components/theme/skeleton/ShDoubleTextSkeleton";
import Heading from "../../../../components/theme/text/Heading";

import cx from 'classnames';
import Text from "../../../../components/theme/text/Text";
import { useEffect, useMemo, useState } from "react";
import { endOfMonth, format, startOfMonth } from "date-fns";
import CallReceivedOutlinedIcon from '@mui/icons-material/CallReceivedOutlined';
import { useGetDashboardRelationOrderChartQuery, useGetDashboardRelationOrderTotalsQuery } from "../../../../newapi/reports/relation/reportRelationSlice";
import OverflowRow from "../../../../components/theme/OverflowRow";
import Subheading from "../../../../components/theme/text/Subheading";
import { useGetDashboardInboundChartQuery, useGetDashboardInboundTotalsQuery } from "../../../../newapi/inbound/inboundsSlice";


const inboundStates = {
    all: "",
    return_count: "return_count",
    fullyreceived_count: "fullyreceived_count",
    active_inbounds: "active_inbounds",
    partlyreceived_count: "partlyreceived_count"
}

const RelationDashboardCard = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            component={"button"}
            onClick={() => props.changeChartType(props.relationChartType)}
            className={cx('',{
                'active': props.active,
            })}
            sx={{
                flex:1,
                flexShrink:0,
                cursor:"pointer",
                background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                border:"none",

                borderRadius:4,
                padding:3,
                display:"flex",
                // flexDirection:"column",

                ".logo-box" : {
                    width:40,
                    height:40,
                    borderRadius:2,
                    background: colors.pastel.light[props.color][100],
                    color:colors.txt["primary"],
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    "& .logo" : {
                        fill: colors.pastel.light[props.color][600],
                    }
                },

                "&.active": {
                    background: colors.pastel.light[props.color][100],
                    ".logo-box" : {
                        background: colors.pastel.light[props.color][200],
                        "& .logo, & .logo path" : {
                            fill: colors.pastel.light[props.color][600],
                        }
                    },
                },
                "@media screen and (max-width: 47.9375em)" : {
                    p:2,
                    // flexDirection:"row",
                    // justifyContent:"space-between",
                    ".logo-box" : {
                        width:32,
                        height:32,
                    }

                }

            }}
        >
            <Box
                sx={{
                    // "@media screen and (max-width: 47.9375em)" : {
                    //     order:2
                    // }
                    
                }}
            >
                <Box className="logo-box">
                    {props.logo}
                </Box>
            </Box>
            {props.isLoading ?
                <ShDoubleTextSkeleton/> 
            : 
                <Box
                    sx={{
                        pl:2,
                        display:"flex",
                        justifyContent:"space-between"
                    }}
                >
                    <Box 
                        sx={{
                            display:"flex",
                            flexDirection:"column",
                            "@media screen and (max-width: 47.9375em)" : {
                                // pl:1,
                            }
                        }}
                    >
                        <Text light>{t(props.data ? props.data[props.type]?.label : "unknown")}</Text>
                        <Heading 
                            className={cx('', {
                                'active':props.active
                            })} 
                            sx={{
                                pt:0.5,
                                "&.active":{
                                    color:`${colors.pastel.light[props.color][600]} !important`
                                }
                            }}
                        >
                            {t(props.data ? props.data[props.type]?.value : "unknown")}
                        </Heading>
                    </Box>
                    {/* <Box 
                        sx={{
                            pl:2,
                            display:"flex",
                            flexDirection:"column",
                            "@media screen and (max-width: 47.9375em)" : {
                                // pl:1,
                            }
                        }}
                    >
                        <Text light>{t(props.data ? props.data[props.type].secondaryLabel : "")}</Text>
                        <Heading 
                            className={cx('', {
                                'active':props.active
                            })} 
                            sx={{
                                pt:0.5,
                                "&.active":{
                                    color:`${colors.pastel.light[props.color][600]} !important`
                                }
                            }}
                        >
                        </Heading>
                    </Box> */}
                </Box>
            }
        </Box>
    )
}

const TopRow = (props) => { 
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // const [type, setTypes] = useState(inboundStates?.openOrders);

    const currentDate = new Date(); // or any date you want to use

    const [totalsDates, setTotalsDates] =useState({
        startDate : format(startOfMonth(currentDate), 'yyyy-MM-dd'),
        endDate : format(endOfMonth(currentDate), 'yyyy-MM-dd'),
    });

    const [chartDates, setChartDates] =useState({
        startDate : format(startOfMonth(currentDate), 'yyyy-MM-dd'),
        endDate : format(endOfMonth(currentDate), 'yyyy-MM-dd'),
    });

    const [ chartDataData, setChartDataData] = useState([]);

    const { data : totalData, isLoading,isFetching } = useGetDashboardInboundTotalsQuery({
        startDate: totalsDates.startDate, 
        endDate: totalsDates.endDate
    });

    const {data: dashData, isLoading: chartIsLoading, isFetching: chartIsFetching, isError: isChartError, refetch: chartRefetch} = useGetDashboardInboundChartQuery({
        startDate: chartDates.startDate, 
        endDate: chartDates.endDate
    });

    useEffect(() => {
        if (!chartIsLoading && !chartIsFetching && dashData && dashData !== chartDataData) {
            setChartDataData(dashData.data); // Directly store the chart data
        }
    }, [dashData, chartIsLoading, chartIsFetching, chartDataData]);

    // Memoize chart data: directly use the data from the backend
    const chartData = useMemo(() => {
        if (!chartDataData) return []; // Return empty if no chart data is available
        return dashData?.data?.series; // Directly use the series returned by the backend
    }, [chartDataData, dashData]);

    return (
        <OverflowRow className={"is-mobile-col"}>
            <Box
                sx={{
                    background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                    flex:2,
                    boxShadow: '0px 1px 2px 1px rgba(49, 65, 95, 0.15)',
                    position:"relative", //Needed for absolutely positioned things
                    // width:1,
                    borderRadius:4,
                    p:3,
                    display:"flex",
                    flexDirection:"column",
                    // "@media screen and (max-width: 47.9375em)" : {
                    //     width: "unset"
                    // }
                }}
            >

                <Box
                    sx={{
                        display:"flex",
                        alignItems:'center',
                        justifyContent:"space-between"
                    }}
                >
                    <Subheading>{t("totals")}</Subheading>
                </Box>
                <Box
                    sx={{
                        mt:3,
                        flex:1,
                        display:"flex",
                        flexDirection:"column",
                    }}
                >
                    <Box
                        sx={{
                            flex:1,
                            display:"flex",
                            borderBottom:`2px solid ${theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100]}`,
                            pt:1.5,
                            pb:3,
                            gap:3,
                            "@media screen and (max-width: 47.9375em)" : {
                                gap:1.5,
                                pb:1.5,
                            }
                        }}
                    >
                        <RelationDashboardCard
                            logo={<CallReceivedOutlinedIcon className="logo"/>}
                            changeChartType={props.setType}
                            relationChartType={inboundStates?.active_inbounds}
                            active={props.type === inboundStates?.active_inbounds}
                            isLoading={isLoading || isFetching}
                            data={totalData}
                            type={"active_inbounds"}
                            color={"lightblue"}
                        />
                        
                        <RelationDashboardCard
                            logo={<CallReceivedOutlinedIcon className="logo"/>}
                            changeChartType={props.setType}
                            relationChartType={inboundStates?.partlyreceived_count}
                            active={props.type === inboundStates?.partlyreceived_count}
                            isLoading={isLoading || isFetching}
                            data={totalData}
                            type={"partlyreceived_count"}
                            displayType={"number"}
                            color="brown"
                        />
                    </Box>
                    <Box
                        sx={{
                            flex:1,
                            display:"flex",
                            gap:3,
                            pt:3,
                            "@media screen and (max-width: 47.9375em)" : {
                                gap:1.5,
                                pb:1.5,
                            }
                        }}
                    >
                        <RelationDashboardCard
                            logo={<CallReceivedOutlinedIcon className="logo"/>}
                            changeChartType={props.setType}
                            relationChartType={inboundStates?.fullyreceived_count}
                            active={props.type === inboundStates?.fullyreceived_count}
                            isLoading={isLoading || isFetching}
                            data={totalData}
                            type={"fullyreceived_count"}
                            color={"green"}

                        />
                        <RelationDashboardCard
                            logo={<CallReceivedOutlinedIcon className="logo"/>}
                            changeChartType={props.setType}
                            relationChartType={inboundStates?.return_count}
                            active={props.type === inboundStates?.return_count}
                            isLoading={isLoading || isFetching}
                            data={totalData}
                            type={"return_count"}
                            color={"pink"}
                        />
                    </Box>

                </Box>
            </Box>
            <Box
                sx={{
                    // overflow:"hidden",
                    flex:3,
                    "@media screen and (max-width: 47.9375em)" : {
                        minWidth: "calc(100% - 48px)"
                    }
                }}
            >
                <AreaChart
                    stacked={false}
                    optionsEnabled={false}
                    onRetry={chartRefetch}
                    isError={isChartError}
                    isLoading={chartIsLoading || chartIsFetching}
                    title={t("through_time")}
                    type="bar"
                    staticColors={[colors.primary[400],colors.primary[200]]}
                    colors={['primary']}
                    categories={dashData?.data?.categories ? dashData.data.categories : []}
                    // categories={Object.keys(dashData?.data?.categories)} // Dates as categories (keys of `chart`)
                    // values={[]}
                    values={chartData}
                    color={"default"}
                    tickAmount={8}
                    filter={
                        <ShDateRangePicker
                            startDate={chartDates.startDate}
                            endDate={chartDates.endDate}
                            size={32}
                            onClose={(value) => {
                                setChartDates({
                                    startDate: value[0].formattedStartDate,
                                    endDate: value[0].formattedEndDate
                                })
                            }}
                        >
                        </ShDateRangePicker>

                    }
                />
            </Box>
            
        </OverflowRow>
    )
}
const Inbounds = () => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [type, setType] = useState(inboundStates?.all);

    // const [preFilter,setPreFilter] = useState({"state": [type]});
    const [preFilter,setPreFilter] = useState({});

    const changeType = (typeToChangeTo) => {
        if(typeToChangeTo === type) {
            setType(inboundStates?.all)
            setPreFilter({})
        } else {
            setType(typeToChangeTo)
            setPreFilter({"state": [typeToChangeTo]});
        }
    }

    return (
        <Box sx={{background:"", width:1,paddingTop:9}}>
            {ready ?
            <>
                <TopRow type={type} setType={changeType}/>

                <TabView3
                    tabConfig={warehouseInboundInboundsConfig}
                    sx={{
                        background:"green",
                        minHeight:"calc(100% - 431px)",
                        background: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                        position:"relative",
                        zIndex:2,
                        display:"flex",
                        flexDirection:"column",

                        "&:before" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            left:0,
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            top:"40px",
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transition:"250ms"
                        },

                        "&:after" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            top:"40px",
                            right:0,
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transform:"scaleX(-1)",
                            transition:"250ms"
                        },

                        "@media screen and (max-width: 47.9375em)" : {
                            "&:after, &:before" : {
                                top:"-0px"
                            }
                        }
                    }}
                >
                    <>
                        <InboundsView filter={preFilter} config={warehouseInboundInboundsConfig.inbounds}/>
                        <InboundInboundLines config={warehouseInboundInboundsConfig.inboundLines}/>
                        <InboundReturns config={warehouseInboundInboundsConfig.returns} />

                    {/* <ActionReq */}
                    </>
                </TabView3>
                {/* </TabView> */}
                

            </>
            :null}
        </Box>
    );
};

export default Inbounds;