import { Box, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../theme";
import Text from "../../../../theme/text/Text";

import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from "react-i18next";
import { useGetStockForProductQuery, useUpdateStockMutation } from "../../../../../newapi/warehouse/stockSlice";
import { general_states } from "../../../../../utils/staticEnums";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import { WarehouseStockWarehouseLocationCard } from "../../../../global/cards/stock/StockWarehouseLocationCard";
import MConfirmationWithValue from "../../../../global/Modals/MConfirmationWithValue";
import MUpdateStock from "../../../../global/Modals/MUpdateStock";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";
import { DropdownBase } from "../../../../theme/dropdowns/Dropdown";
import ShLabel from "../../../../theme/label/ShLabel";
import SidepanelLoader from "../../../../theme/sidepanel2/SidepanelLoader";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";

const SpLocationDetails = SidepanelLoader(() => import("../../SpLocationDetails/SpLocationDetails"));

const GridActions = (props) => {
    return (<></>)
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,

};

const Warehouse = (props) => {
    const  { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [locationIsExtended, setLocationIsExtended] = useState(false);
    const [spLocationLoading, setSpLocationLoading] = useState();
    const [config,setConfig] = useState({productId: props.productId})

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
            mobileActions: <GridActions />,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const [updateStock, {isLoading: updateStockLoading}] = useUpdateStockMutation();
    
    const confirmUnlinkStock = async () => {
        try {
            const updateObj = {
                id: updateStockObject.id,
                state: general_states.UNLINKED
            }
            
            const response = await updateStock(updateObj).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageRequestError(t('update_failed'),t, err);
        }
    };
    
    const [unlinkStockModalOpen, setUnlinkStockModalOpen] = useState(false);
    const unlinkStockModal = {
        open: unlinkStockModalOpen,
        setOpen: setUnlinkStockModalOpen,
        confirm: confirmUnlinkStock,
        data: {
            title: t("unlink_stock"),
            subtitle: t("unlink_stock_confirmation"),
            content: t("are_you_sure_you_want_to_unlink_this_stock")
        }
    } 

    const [updateStockModalOpen, setUpdateStockModalOpen] = useState(false);
    const updateStockModal = {
        open: updateStockModalOpen,
        setOpen: setUpdateStockModalOpen
    }

    const handleUpdateStockClose = () => {
        setUpdateStockModalOpen(false);
    };
    
    const [locationId, setLocationId] = useState();
    const locationIdRef = useRef(locationId);
    
    const getLocationDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.warehouseLocation.id);
        // Only update state if the productId has actually changed
        if (locationIdRef.current !== newProductId) {
            if(!spLocationLoading){
                setSpLocationLoading(true);
            } 
            setLocationId(newProductId);
            locationIdRef.current = newProductId; // Update the ref to the new value
        }
        
        setLocationIsExtended(true);
    };

    const actionsArray = [{
        name: t("move_stock"),
        action: "moveStock"
    }, {
        name: t("mutate_stock"),
        action: "mutateStock"
    }, {
        name: t("unlink_stock"),
        action: "unlinkStock"
    }];

    const [updateStockObject, setUpdateStockObject] = useState(null);
    
    const handleMoveStock = () => {
        console.log(1);
    }

    const handleMutateStock = () => {
        setUpdateStockModalOpen(true);
    }

    const handleUnlinkStock = () => {
        setUnlinkStockModalOpen(true);
    }
    
    const handleActionClick = (action, row) => {
        setUpdateStockObject({
            id: row.id,
            productId: row.product.id,
            warehouseLocationId: row.warehouseLocation.id,
            warehouseLocation: row.warehouseLocation.location,
            warehouse: row.warehouseLocation.warehouse.name,
            available: row.available,
            quantity: row.quantity,
            action: action
        });
    }

    useEffect(() => {
        if (updateStockObject?.action) {
            switch(updateStockObject.action) {
                case "moveStock":
                    handleMoveStock();
                    break;
                case "mutateStock":
                    handleMutateStock();
                    break;
                case "unlinkStock":
                    handleUnlinkStock();
                    break;
            }
        }
    }, [updateStockObject]);

    

    const columns = [
        {
            field: "location", 
            headerName: t('location'), 
            width:216,
            shFilter : {
                type: "text",
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                const { warehouseLocation } = params.row;
              
                return (
                    <Box>
                        <Text bold>
                            {warehouseLocation.location}
                        </Text>
                        <br/>
                        <Text light>
                            {warehouseLocation.warehouse?.name}
                        </Text>
                    </Box>
                );
              }
            },
            {
                field: "location_type",
                headerName: t('type'),
                flex:1,
                renderCell : (params) => {
                    if(params.row.skeleton) {
                        return <ShTextSkeleton/>
                    }

                    const { warehouseLocation } = params.row;

                    const iconMap = {
                        bulk: "box",
                        transition: "truck",
                        general: "pick",
                        link: "link",
                    };
                    const type = iconMap[warehouseLocation?.warehouseLocationType?.type] || warehouseLocation?.warehouseLocationType?.type;

                    const getColor = (icon) => {
                        switch(icon) {
                            case iconMap.bulk :
                                return "grey";
                            case iconMap.transition :
                                return "cyan";
                            case iconMap.general :
                                return theme.palette.mode === "dark" ? "blue" : "primary";

                            case iconMap.link :
                                return "purple";
                            default :
                                return "grey"
                        }
                    }

                    return (
                        <ShLabel size={32} palette={theme.palette.mode === "dark" ? "pastel" : "normal"} variant={getColor(type)}>
                            {warehouseLocation?.warehouseLocationType?.description}
                        </ShLabel>
                    )
                },
            },
            {
                field: "physical",
                headerName: t('physical'),
                flex:1,
                renderCell : (params) => {
                    if(params.row.skeleton) {
                        return <ShTextSkeleton/>
                    }
                    return params.row.quantity ?? 0
                },
                shFilter : {
                    type: "number",
                }
            },
        {
            field: "inOrder",
            headerName: t('in_order'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.inOrder ?? 0
            },
            shFilter : {
                type: "number",
            }
        },
        {
            field: "pickingprocess", 
            headerName: t('in_pick_process'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.pickingprocess ?? 0
            },
            shFilter : {
                type: "number",
            }
        },
        {
            field: "quarantine", 
            headerName: t('quarantine'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.quarantine ?? 0
            },
            shFilter : {
                type: "number",
            }
        },
        {
            field: "administrative",
            headerName: t('administrative'),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.available ?? 0
            },
            shFilter : {
                type: "number",
            }
        },
        {
            field: "actions",
            headerName: "",
            width: 32,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (
                    <DropdownBase
                        stopPropagation
                        displayName={"name"}
                        onChange={(selected) => handleActionClick(actionsArray[selected].action, params.row)}
                        options={params.row.quantity > 0 ? actionsArray.filter(action => action.action !== "unlinkStock") : actionsArray} // Only unlink when no stock on location
                        closeOnSelection
                        styles={{
                            height: 32
                        }}
                        disallowDeselection
                    >
                        <Box
                            sx={{
                                flex:1,
                                // flexGrow:0,
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                                //background:colorSettings.streamrulevalue,
                                height:32,
                                width: 32,
                                borderRadius:4,
                                "&:hover" : {
                                    background:colors.grey[200],
                                    cursor:"pointer",
                                },
                                "@media screen and (max-width: 768px)" : {
                                    // ml:1,
                                }
                            }}
                        >
                            <MenuIcon/>
                        </Box>
                    </DropdownBase>
                )
            },
        },
        // {
        //     field: "pickedUp", 
        //     headerName: t('picked_up'),
        //     flex:1,
        //     renderCell : (params) => {
        //         if(params.row.skeleton) {
        //             return <ShTextSkeleton/>
        //         }
        //         return params.row.available -5
        //     },
        //     shFilter : {
        //         type: "number",
        //     }
        // },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <WarehouseStockWarehouseLocationCard
                        skeleton={props.skeleton ?? false}
                        {...params.row}
                        onClick={() => getLocationDetails(params)}
                    />
                    // <Box
                    //     sx={{
                    //         background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                    //         paddingX:2,
                    //         paddingY:1.5,
                    //         borderRadius:2
                    //     }}
                    // >
                    //     {params.row.state + " " + params.row.available}
                    //     <br/>
                    //     {params.row.backorder}
                    //     <br/>
                    //     {params.row.backorder}
                    // </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetStockForProductQuery}
                onRowClick={getLocationDetails}
                config={config}
                gridActions={<GridActions></GridActions>} 
                title={t("overview")}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns}>
            </ShDataGrid2>

            {spLocationLoading && 
                    <SpLocationDetails
                        isExtended={locationIsExtended}
                        setIsExtended={setLocationIsExtended}
                        locationId={locationId}
                    />
            }
            

            <MConfirmationWithValue
                open={unlinkStockModal.open}
                handleClose={() => unlinkStockModal.setOpen(false)}
                data={unlinkStockModal.data}
                confirm={unlinkStockModal.confirm}
            />
            <MUpdateStock onOpen={() => updateStockModal.setOpen(true)} stockObject={updateStockObject} onClose={() => updateStockModal.setOpen(false)} open={updateStockModal.open} handleClose={handleUpdateStockClose}/>
        </Box>
    );
}
export default Warehouse;