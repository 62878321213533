

import { CallReceivedOutlined, Contacts, LibraryAddCheckOutlined } from '@mui/icons-material';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import InputIcon from '@mui/icons-material/Input';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

export const relationReturnsDesignConfig = {
    home:  {
        title: 'home',
        icon: <AccountTreeOutlinedIcon />,
    },
   
};
