// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import { ReactComponent as EtailizeLogo } from '../../../../../styles/svg/etailize.svg';
import { ReactComponent as ProductflowLogo } from '../../../../../styles/svg/productflow.svg';


/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    
        const columns = [
            {title: "E-Tailize", logo: <EtailizeLogo sx={{width: "100px", height: "60px"}}/>, key: "etailize"},
             {title: "Productflow", logo: <ProductflowLogo width={60} height={60}/>, key: "productflow"},
        ]

        const handleOptionSelect = (option) => {
            const credentialsObj = getCredentialsFromType(columns[option].key);
            props.handleDataUpdate({ credentialsInfo: credentialsObj });
            props.handleDataUpdate({ selectedOption: option, type: columns[option].key });
            props.handleNext("tabCredentials");
        };

        const getCredentialsFromType = (type) => {
            switch(type) {
                case "etailize": 
                    return {email: "", firstName: "", lastName: "", companyName: "", externalUserIdentifier: ""}; 
                case "productflow": 
                    return {apiKey: "", companyId: ""}; 
                default:
                    return {};
            }
        }
    
        return (
            <div>
                <SelectGroup selected={-1} setSelected={handleOptionSelect} options={columns} image={true} />
            </div>
        );
    };

const ChooseIntegratorType = (props) => {
    return (
        <ProgressionTab tabId="tabChooseType" step={1} maxSteps={4}>
            <Content/>
        </ProgressionTab>
    )
}

export default ChooseIntegratorType;