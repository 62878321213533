import { Box, useTheme } from "@mui/material"
import BaseCard from "../../../theme/cards/BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../../theme/text/Text";
import CardLabel from "../../../theme/label/CardLabel";
import TopRight from "../../../theme/positionals/TopRight";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ShDoubleTextSkeleton from "../../../theme/skeleton/ShDoubleTextSkeleton";
import ShLabel from "../../../theme/label/ShLabel";
import ShTextSkeleton from "../../../theme/skeleton/ShTextSkeleton";
import { getOrderStateData } from "../../../../utils/labelColorText";

const OrdersCard = (props) => {
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [send, setSend] = useState(null);
    const [planned, setPlanned] = useState(null);
    const [statusColor, setStatusColor] = useState("blue");
    const [statusText, setStatusText] = useState("");
    // const solved = props.row.solved ?? false;
    // const solvedColor = solved ? "green" : "red";

    const [plannedColor, setPlannedColor] = useState("grey");

    useEffect(() => {
        // console.log("skellytime",props.skeleton);
        if(!props.skeleton) {
            let planned = new Date(props.date_planned);
            let sent = props.date_sent ? new Date(props.date_sent) : null;

            setSend(sent);
            setPlanned(new Date(props.date_planned));
        
            if(sent) {
                // when sent is filled and is equal to planned: green
                if(sent.getTime() === planned.getTime()) {
                    // labelcolor = "green";
                    setPlannedColor("green");
                }
                // when sent is filled but later than planned: orange
                else if(sent.getTime() > planned.getTime()) {
                    // labelcolor = "orange";
                    setPlannedColor("orange");
                }
            }
            else {
                // When sent is not filled and planned >= now: blue
                if(planned.getTime() >= Date.now()) {
                    setPlannedColor("blue");
                }
                // When sent is not filled and planned < now: red
                else if(planned.getTime() < Date.now()) {
                    setPlannedColor("red");
                }
            }
            const { labelcolor, labeltext } = getOrderStateData(props.state, theme);
            setStatusColor(labelcolor);
            setStatusText(labeltext);
              
        }

    },[props.skeleton])

   

    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box
                >
                    <Box display={"flex"} flexDirection={"column"}>
                        {props.skeleton ? 
                            <ShDoubleTextSkeleton/> 
                            :
                            <>
                                <Text bold>{props.id}</Text>
                                <Text light>{props.deliveryName}</Text>
                            </>
                        }
                    </Box>
                    <TopRight>

                    {/* {!sent && planned.toLocaleDateString(t.language)}
                        {sent && sent.toLocaleDateString(t.language)} */}
                        <CardLabel variant={props.skeleton ? theme.palette.mode === "dark" ? "greyLight" : "grey" : plannedColor}  position="child-tr">
                            {props.skeleton ? 
                                <ShDoubleTextSkeleton/> 
                                :
                                <>
                                    <Text bold variant={plannedColor}>
                                        {!send && planned?.toLocaleDateString(i18n.language)}
                                        {send && send?.toLocaleDateString(i18n.language)}
                                    </Text>
                                </>
                            }
                        </CardLabel>
                    </TopRight>

                    <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={3}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("lines")}</Text>

                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>
                                    {props.totalOrderLines ?? 0}
                                    {/* {myDate} */}
                                    {/* {props.stock && props.stock.total_quantity ? props.stock.total_quantity : 0}     */}
                                </Text>
                            }
                        </Box>

                        <Box 
                            sx={{
                                ml:"auto",
                                mt:"auto"
                            }}
                        >
                            <ShLabel fitted borderless palette={"normal"} size={32} variant={props.skeleton ? "grey" : statusColor}  position="child-b">
                                {props.skeleton ?null : t(statusText)}
                            </ShLabel>
                        </Box>
                    </Box>

                    
                </Box>
            </Box>
        </BaseCard>
    )
}

export default OrdersCard;