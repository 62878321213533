import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../../components/theme/buttons/Shbutton";

import Text from "../../../../components/theme/text/Text";
import { tokens } from "../../../../theme";

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import ShLabel from "../../../../components/theme/label/ShLabel";
import { useGetReturnsQuery } from "../../../../newapi/inbound/returnsSlice"; // Changed from inboundsSlice to returnsSlice
// import ReturnsCard from "../../../../components/global/cards/returns/ReturnsCard"; // Changed from InboundsCard to ReturnsCard
import { PhonelinkOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RelationReturnCard } from "../../../../components/global/cards/inbounds/ReturnCards";
import ButtonGroup from "../../../../components/theme/buttons/ButtonGroup";
import ShDataGrid2 from "../../../../components/theme/datagrid2/ShDataGrid2";
import SidepanelLoader from "../../../../components/theme/sidepanel2/SidepanelLoader";
import ShLabelSkeleton from "../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetRelationQuery } from "../../../../newapi/global/relation/relationSlice";
import { getInboundStateData } from '../../../../utils/labelColorText';

const SpReturnDetails = SidepanelLoader(() => import("../../../../components/global/Sidepanels/SpReturnDetails/SpReturnDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "primaryGrey" : "primaryGrey"} 
                onClick={() => props.handlePortalOpen()}
                endIcon={<PhonelinkOutlined/>}
                variant="contained"
            >
                {t('portal')}
            </Shbutton>
        </ButtonGroup>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,

};

const Returns = (props) => { // Changed from InboundInbounds to ReturnReturns
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);
    const id = useSelector((state) => state.settings.environment.selectedId);
    const [spLoading, setSpLoading] = useState();

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
            
        }
    },[]);

    const [relationId, setRelationId] = useState();
    const { data: relation } = useGetRelationQuery(relationId, {
        skip: !relationId
    });
    
    const handlePortalOpen = () => {
        if(!relation){
            setRelationId(id);
        } else {
            window.open(`https://returns.stockhub.nl/${relation.portalCode}`, '_blank');
        }
    }


    const [returnId, setReturnId] = useState();
    const returnIdRef = useRef(returnId);
    
    const getReturnDetails = (params) => {
        if (!params) return;
    
        const newreturnId = parseInt(params.row.id);
        // Only update state if the returnId has actually changed
        if (returnIdRef.current !== newreturnId) {
            
            if(!spLoading) {
                setSpLoading(true);
            }
            setReturnId(newreturnId);
            returnIdRef.current = newreturnId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const columns = [
        {field: "name", headerName: t("name"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return(
                    <Text>
                        {params.row.name}
                    </Text>
                )
            }
        },
        {field: "reference", headerName: t("reference"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.order?.webshopreference;
            }
        },
        {
            field: "createdAt", 
            headerName: t("created"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                let date = new Date(params.row.createdAt);
              
                return (
                    <Box>
                        <Text semibold>
                            {date.toLocaleDateString(i18n.language)}
                        </Text><br/>
                        <Text light>
                            {date.toLocaleTimeString(i18n.language,{timeStyle: 'short'})}
                        </Text>
                    </Box>
                );
            }
        },
        {field: "skuCount", headerName: t("sku_count"),flex:1,
            sortable:false,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.skuCount;
            }
        },        

        {field: "itemCount", headerName: t("items"),flex:1,
            sortable:false,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.itemCount;
            }
        },
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }

                const { labelcolor, labeltext } = getInboundStateData(params.row.state, theme);
              
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {t(labeltext)}
                    </ShLabel>
                  );
              }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <RelationReturnCard onClick={() => console.log("hello")} skeleton={params.row.skeleton ?? false} {...params.row}/>
                    // <InboundsCard skeleton={params.row.skeleton ?? false} {...params.row} />
                // <Box padding={3} sx={{background:"orange"}}></Box>
                    // <Box
                    //     sx={{
                    //         background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                    //         paddingX:2,
                    //         paddingY:1.5,
                    //         borderRadius:2
                    //     }}
                    // >
                    //     {cellValues.row.id + " " + cellValues.row.name}
                    //     <br/>
                    //     {cellValues.row.age}
                    //     <br/>
                    //     {cellValues.row.phone}
                    // </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetReturnsQuery} // Changed from useGetInboundsQuery to useGetReturnsQuery
                gridActions={<GridActions handlePortalOpen={handlePortalOpen}></GridActions>} 
                title={t('all_returns') } // Changed from all_inbounds to all_returns
                gridOptions={gridOptions} 
                sortModel={{field: columns[2].field,type:"DESC"}}
                columns={columns}
                onRowClick={getReturnDetails}> 
            </ShDataGrid2>

            {spLoading && 
                    <SpReturnDetails // Changed from SpInboundDetails to SpReturnDetails
                        isExtended={isExtended}
                        setIsExtended={setIsExtended}
                        returnId={returnId}
                    />
            }
            
        </Box>
    );
}

export default Returns; // Changed from InboundInbounds to ReturnReturns
