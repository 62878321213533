// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { setProgressionInfo, setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';
import cx from "classnames"
import { useGetRelationsQuery } from '../../../../../newapi/global/relation/relationSlice';
import ShValidatedInputDropdown from '../../../../theme/dropdowns/ShValidatedInputDropdown';
import { useGetConnectionDefinitionsQuery } from '../../../../../newapi/webshop/etailizeSlice';
import ShLabel from '../../../../theme/label/ShLabel';
import { useGetIntegratorMarketplacesQuery } from '../../../../../newapi/webshop/integratorSlice';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const dispatch = useDispatch();
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);

    const handleInput = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    const [credentialsInfo, setCredentialsInfo] = useState(data.credentialsInfo);

    const handleCredentialsChange = (key, val) => {
        setCredentialsInfo({
            ...credentialsInfo,
            [key]: val
        });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    }

    // const { data: connectionDefinitions, isLoading: connectionDefinitionsLoading, isError: connectionDefinitionsError } = useGetConnectionDefinitionsQuery(credentialsInfo,
    //     {
    //         skip: !credentialsInfo.apiKey || !credentialsInfo.apiSecret || !credentialsInfo.clientId || data.type !== "etailize"
    //     }
    // );

    const { data: marketplaces, isLoading: marketplacesLoading, isError: marketplacesError } = useGetIntegratorMarketplacesQuery({ ...credentialsInfo, type: data.type },
        {
            skip: ((data.type == "productflow" && (credentialsInfo.apiKey == '' || credentialsInfo.companyId == '')) 
            || (data.type == "etailize" && (credentialsInfo.email == '' || credentialsInfo.firstName == '' || credentialsInfo.lastName == '' || credentialsInfo.companyName == '' || credentialsInfo.externalUserIdentifier == '')))
        }
    );
    

    useEffect(() => {
        if(marketplacesError || !credentialsInfo.apiKey || !credentialsInfo.companyId || data.type !== "productflow") {
            props.handleDataUpdate({ availableChannels: null });
            dispatch(setProgressionInfo({nextDisabled:true}));
            return;
        }
        if (marketplaces) {
            const updatedMarketplaces = marketplaces.map(definition => ({
                ...definition,
                active: true,
                syncOrders: true,
                warehouseId: -1
            }));
            props.handleDataUpdate({ availableChannels: updatedMarketplaces });
            dispatch(setProgressionInfo({nextDisabled:false}));
        }   
    }, [marketplaces, marketplacesError]);

    useEffect(() => {
        console.log("data",props.data);
    }, [props.data]);

    useEffect(() => {
        props.handleDataUpdate({ credentialsInfo: credentialsInfo });
    }, [credentialsInfo])

    // Fetch relations using the useGetRelationsQuery hook
    const { data: relationsData, isLoading: relationsLoading } = useGetRelationsQuery({
        args: { page: 1, size: 100, body: {} }, // Adjust the args as needed
    });

    return (
        <div>
            { isFulfilment &&
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 2,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7,
                        mb:1,
                    }}
                >
                    
                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("relation")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInputDropdown
                                name="relationId"
                                displayName="name"
                                changeField="id"
                                selected={relationsData?.data?.findIndex(relation => relation.id === data?.relationId) ?? 0}
                                noSelection={t("choose_relation")} //Custom text when nothing is selected
                                options={relationsData?.data ?? []}
                                onChange={handleInput}
                            />
                        
                    </Box>
                </Box>
            }
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 2,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    
                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text>{t("name")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInput
                                name="name"
                                value={data.name ?? ""}
                                onChange={handleInput}
                                error={props.errors.name}
                            />
                        
                    </Box>
                </Box>
            {Object.keys(credentialsInfo)
                .filter(key => key !== "measuring_units")
                .map((key) => (
                    <Box 
                        key={key}
                        sx={{
                            width: 1,
                            paddingTop: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 7
                        }}
                    >
                        <Box
                            sx={{
                                width: 120,
                            }}
                        >
                            <Text>{t(key)}</Text>
                        </Box>
                        
                        <Box
                            sx={{
                                flex: 1,
                                //backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                                borderRadius: 4,
                                display: "flex",
                                height: 32
                            }}
                        >
                            {key.includes('_bool') ? (
                                <ToggleButtonGroup
                                    color={credentialsInfo[key] === 'true' ? "green" : "red"}
                                    value={credentialsInfo[key] === 'true' ? "true" : "false"}
                                    exclusive
                                    onChange={(e,value) => handleCredentialsChange(key, value)}
                                    aria-label="Active"
                                    className={cx(`${credentialsInfo[key]}`, {
                                        'active': credentialsInfo[key] === 'true'
                                    })}
                                    sx={{
                                        background: colors.red[100],
                                        padding:0.5,
                                        borderRadius:5,
                                        transition:"250ms",
                                        "&.active" : {
                                            background:colors.green[100],
                                            transition:"250ms",
                                        },
                                    }}
                                >
                                    <ToggleButton 
                                        sx={{
                                            padding:0,
                                            paddingX:2,
                                            border:0,
                                            lineHeight:"32px",
                                            textTransform:"unset",
                                            borderRadius:"32px !important",
                                            color:colors.green[400],
                                            transition:"250ms",
                                            "&.Mui-selected,&.Mui-selected:hover" : {
                                                background:colors.red[400],
                                                color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                            }
                                        }}
                                        value="false">
                                        {t('inactive')}
                                    </ToggleButton>
                                    <ToggleButton 
                                        sx={{
                                            padding:0,
                                            paddingX:2,
                                            border:0,
                                            lineHeight:"32px",
                                            textTransform:"unset",
                                            borderRadius:"32px !important",
                                            color:colors.red[400],
                                            transition:"250ms",
                                            "&.Mui-selected,&.Mui-selected:hover" : {
                                                background:colors.green[400],
                                                color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                            }
                                        }}
                                        value="true"
                                    >
                                        {t('active')}
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            ) : (
                                <ShValidatedInput
                                    name={key}
                                    value={credentialsInfo[key] ?? ""}
                                    onChange={handleCredentialsChange}
                                    error={props.errors[key]}
                                />
                            )}
                        </Box>
                        
                    </Box>
            ))}
            <Box 
                sx={{
                    width: 1,
                    paddingTop: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 7
                }}
                >
                     <Box
                        sx={{
                            width: 120,
                        }}
                    >
                        <Text>{t('connection_status')}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex: 1,
                            //backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                            borderRadius: 4,
                            display: "flex",
                            height: 32
                        }}
                    >

                        {(
                            marketplacesLoading ? 
                                <ShLabel variant="blue" palette="normal" fitted>{t('connecting')}</ShLabel>
                            : marketplacesError ? 
                                <ShLabel variant="red" palette="normal" fitted>{t('connection_failed')}</ShLabel>
                            : marketplaces ? 
                                <ShLabel variant="green" palette="normal" fitted>{`${marketplaces.length} ${t('connected_marketplaces_found')}`}</ShLabel>
                            : 
                                <ShLabel variant="grey" palette="normal" fitted>{t('not_connected')}</ShLabel>
                        )}
                    
                    </Box>
                </Box>
    </div>
    );
};

const Credentials = (props) => {
    const { t } = useTranslation();
    // let schema = {
    //     description: yupRequired('description', t),
    // };

    // if (data && data.webshop_type === "woocommerce") {
    //     schema = {
    //         ...schema,
    //         url: yupRequired('url', t),
    //         consumer_key: yupRequired('consumer_key', t),
    //         consumer_secret: yupRequired('consumer_secret', t),
    //     };
    // } else if (data && data.webshop_type === "shopify") {
    //     schema = {
    //         ...schema,
    //         shop: yupRequired('shop', t),
    //         client_key: yupRequired('client_key', t),
    //         access_token: yupRequired('access_token', t),
    //     };
    // }
    const {data} = useSelector((state) => state.progressiontabs);
    
    return (
        <ProgressionTab 
            tabId="tabCredentials" 
            subtitle={t("integratorcredentials")} 
            step={2}
            nextTab={"tabSync"}
            maxSteps={4}
            nextDisabled={data.availableChannels === null}
            // schema={schema}
        >
            <Content />
        </ProgressionTab>
    )
}

export default Credentials;

