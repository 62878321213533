
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";

import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import MAddConceptInvoice from "../../../../../components/global/Modals/MAddConceptInvoice";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import ShLabel from "../../../../../components/theme/label/ShLabel";
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import Text from "../../../../../components/theme/text/Text";
import { useGetInvoicesQuery } from "../../../../../newapi/financial/invoiceSlice";
import { general_states } from "../../../../../utils/staticEnums";
// import SpRelationDetails from "../../../../../components/global/Sidepanels/SpRelationDetails/SpRelationDetails";
// import SpInvoiceDetails from "../../../../../components/global/Sidepanels/SpInvoiceDetails/SpInvoiceDetails";
import { WarehouseInvoiceCard } from "../../../../../components/global/cards/financial/InvoiceCard";
import SidepanelLoader from "../../../../../components/theme/sidepanel2/SidepanelLoader";
import { formatPrice } from "../../../../../utils/helpers";
import { getInvoiceStateData } from "../../../../../utils/labelColorText";

const SpRelationDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpRelationDetails/SpRelationDetails"));
const SpInvoiceDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpInvoiceDetails/SpInvoiceDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('new_concept_invoice')}
            </Shbutton>
        </ButtonGroup>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,

};

const All = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended, setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const [isRelationExtended, setIsRelationExtended] = useState(false);
    const [spRelationLoading, setSpRelationLoading] = useState();
    // const [selectedRowId, setSelectedRowId] = useState(-1);
    // const [rowSelected, setRowSelected] = useState(false);
    
    const ref = useRef();
    const [config, setConfig] = useState({"state": general_states.ACTIVE});

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
        }
    },[]);

    const handleOpen = () => {
        setAddModalOpen(true);
    }
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = (invoiceId) => {
        setAddModalOpen(false);
        getInvoice({row: {id: invoiceId}});
    };

    const [relationId, setRelationId] = useState();
    const relationIdRef = useRef(relationId);
    
    const getRelationDetails = (params) => {
        if (!params && params.row) return;
    
        const newRelationId = parseInt(params.row.relation.id);
        // Only update state if the productId has actually changed
        if (relationIdRef.current !== newRelationId) {
            if(!spRelationLoading) {
                setSpRelationLoading(true);
            }
            setRelationId(newRelationId);
            relationIdRef.current = newRelationId; // Update the ref to the new value
        }
        setIsRelationExtended(true);
    };

    const [invoiceId, setInvoiceId] = useState();
    const invoiceIdRef = useRef(invoiceId);
    
    const getInvoice = (params) => {
        if (!params) return;
    
        const newInvoiceId = parseInt(params.row.id);
        // Only update state if the pickingJobListId has actually changed
        if (invoiceIdRef.current !== newInvoiceId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setInvoiceId(newInvoiceId);
            invoiceIdRef.current = newInvoiceId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const columns = [
        {
            field: "number",
            headerName: t("number"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
    
                return params.row.number;
            }
        },
        {field: "relation", headerName: t("relation"),flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            const handleClick = (event) => {
                event.stopPropagation(); // Prevent click from propagating to the row
                getRelationDetails(params);
            };
            return (
                <Box sx={{height:1,display:"flex",alignItems:'center'}} onClick={handleClick}>
                    <Text bold clickable={true} variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.relation?.name}</Text>
                </Box>
            );
        }},
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }

                const stateData = getInvoiceStateData(params.row.state, theme);
                let labelcolor = stateData.labelcolor;
                let labeltext = stateData.labeltext;
                
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {t(labeltext)}
                    </ShLabel>
                  );
              }
        },
        {
            field: "date", 
            headerName: t("invoice_date"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                let date = params.row.date && params.row.date !== null ? new Date(params.row.date) : undefined;
              
                return (
                    <Text>
                        {date ? date.toLocaleDateString(i18n.language) : "-"}
                    </Text>
                );
            }
        },
        {
            field: "totalPrice", 
            headerName: t("total_price"),
            flex:1,
            shFilter: {
                type: "number"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return formatPrice(i18n, params.row.totalPrice);
            }
        },
        {
            field: "period", 
            headerName: t("period"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                let startDate = params.row.startDate && params.row.startDate !== null ? new Date(params.row.startDate) : undefined;
                let endDate = params.row.endDate && params.row.endDate !== null ? new Date(params.row.endDate) : undefined;
              
                return (
                    <Text>
                        {startDate.toLocaleDateString(i18n.language)} - {endDate ? endDate.toLocaleDateString(i18n.language) : t("present")}
                    </Text>
                );
            }
        },
        {
            field: "externalReference", 
            headerName: t("external_reference"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.externalReference;
            }
        },
        {
            field: "totalLines",
            headerName: t("invoicelines"),
            width:144,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
    
                return params.row.totalLines;
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                // WarehouseInvoiceCard
                return (
                    <WarehouseInvoiceCard
                        onClick={() => getInvoice(params)}
                        skeleton={params.row.skeleton}
                        {...params.row}
                    />
                    // <ShTextSkeleton/>
                )
            }
        },
    ];

    return (
        <Box height={1} ref={ref} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetInvoicesQuery}
                config={config}
                gridActions={<GridActions handleOpen={handleOpen}></GridActions>} 
                title={t('all')}
                gridOptions={gridOptions} 
                sortModel={{field: columns[0].field,type:"DESC"}}
                columns={columns}
                rows={data}
                onRowClick={getInvoice}
                >
            </ShDataGrid2>

            {spLoading && 
                <SpInvoiceDetails
                    invoiceId={invoiceId}
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    // data={pickingJobData}
                /> 
            }

            {spRelationLoading && 
                <SpRelationDetails
                    relationId={relationId}
                    isExtended={isRelationExtended}
                    setIsExtended={setIsRelationExtended}
                    // data={pickingJobData}
                /> 
            }


            
            <MAddConceptInvoice onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
        </Box>
    );
}

export default All;