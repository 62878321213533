import { rootSlice } from "../../createRootSlice";
//import { muiDataGridStarted } from "../apiutils";
const RELATION_BASE = "relations";

export const relationRootSlice =  rootSlice.injectEndpoints({
    endpoints: (builder) => ({
        // getRelations: builder.query({
        //     query: ({ args, invalidate }) => ({
        //         url: `/${RELATION_BASE}?page=${args.page}&size=${args.size}`,
        //         method: 'POST',
        //         body: args.body,
        //     }),
        //     providesTags: () => [{ type: 'Relations', identifier: 'datatable' }],
        //     onQueryStarted: muiDataGridStarted.bind(null, 'getRelations', 'Relations')
        // }),
        // getRelation: builder.query({ 
        //     query: (id) => ({ 
        //         url: `/${RELATION_BASE}/${id}`, 
        //         method: 'GET', 
        //     }),
        //     providesTags: (id) => [{ type: `SingleRelation-${id}`}],
        // }),
        createRootRelation: builder.mutation({ 
            query: (relation) => ({ 
                url: `/${RELATION_BASE}/create`, 
                method: 'POST', 
                body: relation
            }),
            invalidatesTags: (relation) => [
                { type: 'Relations', identifier: 'datatable' },
                { type: 'Relations', identifier: 'select' },
                { type: `SingleRelation-${relation.id}` }
            ],
        }),
        // editRelation: builder.mutation({ 
        //     query: (relation) => ({ 
        //         url: `/${RELATION_BASE}/${relation.id}`, 
        //         method: 'PUT', 
        //         body: relation
        //     }),
        //     invalidatesTags: (relation) => [
        //         { type: 'Relations', identifier: 'datatable' },
        //         { type: 'Relations', identifier: 'select' },
        //         { type: `SingleRelation-${relation.id}` }
        //     ],
        // }),
        // getRelationsSelect: builder.query({
        //     query: () => ({
        //         url: `/${RELATION_BASE}`,
        //         method: 'GET',
        //     }),
        //     providesTags: () => [{ type: 'Relations', identifier: 'select' }],
        // }),
        deleteRootRelation: builder.mutation({ 
            query: (id) => ({ 
                url: `/${RELATION_BASE}/${id}`, 
                method: 'DELETE', 
            }),
            invalidatesTags: (relation) => [
                { type: 'Relations', identifier: 'datatable' },
                { type: 'Relations', identifier: 'select' },
                { type: `SingleRelation-${relation.id}` }
            ],
        }),
    }),
    
});

export const { useCreateRootRelationMutation, useDeleteRootRelationMutation} = relationRootSlice;
//export const { useGetRelationsQuery, useGetRelationQuery, useCreateRelationMutation, useEditRelationMutation, useGetRelationsSelectQuery, useDeleteRelationMutation} = rootSlice;