import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import Text from "../../../../components/theme/text/Text";
import { tokens } from "../../../../theme";

import OverflowRow from "../../../../components/theme/OverflowRow";

import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
// import { getOrderStatusCounts } from "../../../../api/orders/orders";
import cx from 'classnames';
import { endOfMonth, format, startOfMonth } from "date-fns";
import { useTranslation } from 'react-i18next';
import ShDoubleTextSkeleton from "../../../../components/theme/skeleton/ShDoubleTextSkeleton";
import TabView3 from "../../../../components/theme/TabView3/TabView3";
import Heading from "../../../../components/theme/text/Heading";
import { useGetDashboardOrderTotalsQuery } from "../../../../newapi/order/ordersSlice";
import OutboundActionRequiredView from "./views/ActionRequired";
import OutboundOrdersView from "./views/Orders";
import OutboundPriorityView from "./views/Priority";
import OutboundReplenishView from "./views/Replenish";
import OutboundSnoozedView from "./views/Snoozed";
import { warehouseOutboundOrdersConfig } from "./warehouseOutboundOrdersConfig";


const locationChartStates = {
    open_count: "open_count",
    replenish_count: "replenish_count",
    backorder_count: "backorder_count",
    due_count: "due_count"
}

const OrderDashboardCard = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    const calculateValueComp = () => {
        if(props.value && props.type.includes("InUse")) {
            const valueSplitted = props.value.split("/");

            return (
                <Box sx={{
                    display:"flex",
                }}>
                    <Heading 
                        className={cx('', {
                            'active':props.active
                        })} 
                        sx={{
                            pt:0.5,
                                color:`${colors.pastel.light[props.color][600]} !important`,
                            "&.active":{
                                color:`${colors.pastel.light[props.color][600]} !important`
                            }
                        }}
                    >
                        {valueSplitted[0].trim()}
                    </Heading>
                    <Heading 
                        className={cx('', {
                            'active':props.active
                        })} 
                        sx={{
                            pt:0.5,
                            color:colors.txt["secondary"],
                                color:`${colors.pastel.light[props.color][600]} !important`,
                            "&.active":{
                                fontWeight:"normal",
                                color:`${colors.pastel.light[props.color][600]} !important`
                            }
                        }}
                    >
                        &nbsp;/ {valueSplitted[1].trim()}
                    </Heading>
                </Box>
            )
        }
        return (

            <Heading 
                className={cx('', {
                    'active':props.active
                })} 
                sx={{
                    pt:0.5,
                                color:`${colors.pastel.light[props.color][600]} !important`,
                    "&.active":{
                        color:`${colors.pastel.light[props.color][600]} !important`
                    }
                }}
            >
                {props.value}
            </Heading>
        )
    };


    return (
        <Box
            component={"button"}
            onClick={() => props.changeChartType(props.locationChartType)}
            className={cx('',{
                'active': props.active,
            })}
            sx={{
                flex:1,
                cursor:"pointer",
                // background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                background: theme.palette.mode === "dark" ?  colors.pastel.light[props.color][100] :  colors.pastel.st[props.color][100],
                boxShadow: `0px 2px 4px ${colors.pastel.st[props.color][300]}`,
                border:"none",

                borderRadius:4,
                padding:3,
                display:"flex",
                alignItems:"center",

                ".logo-box" : {
                    width:40,
                    height:40,
                    borderRadius:2,
                    background: colors.pastel.st[props.color][200],
                    color:colors.txt["primary"],
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    "& .logo" : {
                        fill: colors.pastel.st[props.color][600],
                    }
                },

                // "&.active": {
                //     background: colors.pastel.light[props.color][100],
                //     ".logo-box" : {
                //         background: colors.pastel.light[props.color][200],
                //         "& .logo, & .logo path" : {
                //             fill: colors.pastel.light[props.color][600],
                //         }
                //     },
                // },
                "@media screen and (max-width: 47.9375em)" : {
                    p:2,
                    // flexDirection:"row",
                    // justifyContent:"space-between",
                    ".logo-box" : {
                        width:32,
                        height:32,
                    }

                }

            }}
        >
            <Box
                sx={{
                    // "@media screen and (max-width: 47.9375em)" : {
                    //     order:2
                    // }
                    
                }}
            >
                <Box className="logo-box">
                    {props.logo}
                </Box>
            </Box>
            {props.isLoading || !props.data ?
                <ShDoubleTextSkeleton/> 
            : 
                <Box 
                    sx={{
                        pl:2,
                        display:"flex",
                        flexDirection:"column",
                        "@media screen and (max-width: 47.9375em)" : {
                            // pl:1,
                        }
                    }}
                >
                    <Text light noBreak>{t(props.type)}</Text>
                    
                    <Heading 
                        className={cx('', {
                            'active':props.active
                        })} 
                        sx={{
                            pt:0.5,
                                        color:`${colors.pastel.light[props.color][600]} !important`,
                            "&.active":{
                                color:`${colors.pastel.light[props.color][600]} !important`
                            }
                        }}
                    >
                        {props.value}
                    </Heading>
                </Box>
            }
        </Box>
    )
}

const TopRow = () => { 
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [chartType, setChartType] = useState(locationChartStates?.open_count);

    const changeChartType = (type) => {
        setChartType(type);
    }

    const currentDate = new Date(); // or any date you want to use

    const [totalsDates, setTotalsDates] =useState({
        startDate : format(startOfMonth(currentDate), 'yyyy-MM-dd'),
        endDate : format(endOfMonth(currentDate), 'yyyy-MM-dd'),
    });

    // const [chartDates, setChartDates] =useState({
    //     startDate : format(startOfMonth(currentDate), 'yyyy-MM-dd'),
    //     endDate : format(endOfMonth(currentDate), 'yyyy-MM-dd'),
    // });



    const { data : totalData, isLoading,isFetching } = useGetDashboardOrderTotalsQuery({
        startDate: totalsDates.startDate, 
        endDate: totalsDates.endDate
    });

    
    return (
        <OverflowRow>
            <Box
                sx={{
                    // background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                    flex:2,
                    // boxShadow: '0px 1px 2px 1px rgba(49, 65, 95, 0.15)',
                    position:"relative", //Needed for absolutely positioned things
                    // width:1,
                    borderRadius:4,
                    display:"flex",
                    gap:3,
                    // "@media screen and (max-width: 47.9375em)" : {
                    //     width: "unset"
                    // }
                }}
            >

                
                        <OrderDashboardCard
                            logo={<CallMadeOutlinedIcon className="logo"/>}
                            changeChartType={changeChartType}
                            locationChartType={locationChartStates?.open_count}
                            isLoading={isLoading || isFetching}
                            data={totalData}
                            value={totalData?.open_count.value}
                            type={totalData?.open_count.label}
                            displayType={"text"}
                            color={"lightblue"}

                        />

                        <OrderDashboardCard
                            logo={<CallMadeOutlinedIcon className="logo"/>}
                            changeChartType={changeChartType}
                            locationChartType={locationChartStates?.due_count}
                            isLoading={isLoading || isFetching}
                            data={totalData}
                            type={totalData?.due_count.label}
                            value={totalData?.due_count.value}
                            displayType={"text"}
                            color="red"
                        />
                        <OrderDashboardCard
                            logo={<CallMadeOutlinedIcon className="logo"/>}
                            changeChartType={changeChartType}
                            locationChartType={locationChartStates?.replenish_count}
                            isLoading={isLoading || isFetching}
                            data={totalData}
                            value={totalData?.replenish_count.value}
                            type={totalData?.replenish_count.label}
                            displayType={"text"}
                            color={"grey"}
                        />

                        <OrderDashboardCard
                            logo={<CallMadeOutlinedIcon className="logo"/>}
                            changeChartType={changeChartType}
                            locationChartType={locationChartStates?.backorder_count}
                            isLoading={isLoading || isFetching}
                            data={totalData}
                            type={totalData?.backorder_count.label} 
                            value={totalData?.backorder_count.value}
                            color={"grey"}
                            displayType={"text"}
                        />
                        
                </Box>
           
            
        </OverflowRow>
    )
}

const OutboundOrders = () => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // const ref = useRef();

    //  const [ data, setData] = useState();

    // const { data : incData, isLoading } = useGetDashboardOrderStatusCountsQuery();
    // useEffect(() => {
    
    //     if (!isLoading && incData) {
    //         setData(incData);
    //     }
    // }, [incData, isLoading]);

    // useEffect(() => {
    //     if(ref.current != null) {
    //         console.log("awa", ref.current.getBoundingClientRect());
    //     }
    // }, [ref.current])
  

    return (
        <Box sx={{background:"", width:1,paddingTop:9}}>
            {ready ?
            <>
                <TopRow/>

                <TabView3
                    tabConfig={warehouseOutboundOrdersConfig}
                    // ref={ref}
                    sx={{
                        background:"green",
                        minHeight:"calc(100% - 190px)",
                        background: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                        position:"relative",
                        zIndex:2,
                        display:"flex",
                        flexDirection:"column",

                        "&:before" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            left:0,
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            top:"40px",
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transition:"250ms"
                        },

                        "&:after" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            top:"40px",
                            right:0,
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transform:"scaleX(-1)",
                            transition:"250ms"
                        },

                        "@media screen and (max-width: 47.9375em)" : {
                            "&:after, &:before" : {
                                top:"-0px"
                            }
                        }
                    }}
                >
                    <>
                        <OutboundOrdersView config={warehouseOutboundOrdersConfig.orders}/>
                        {/* <ActionRq */}
                        <OutboundActionRequiredView config={warehouseOutboundOrdersConfig.actionRequired} />
                        <OutboundReplenishView config={warehouseOutboundOrdersConfig.replenish} />
                        <OutboundPriorityView config={warehouseOutboundOrdersConfig.priority}/>
                        <OutboundSnoozedView config={warehouseOutboundOrdersConfig.snoozed}/>
                    {/* <ActionReq */}
                    </>
                </TabView3>
                {/* </TabView> */}
            </>
            :null}
        </Box>
    );
};

export default OutboundOrders;