import { createSlice } from '@reduxjs/toolkit';
import { PURGE, REHYDRATE } from 'redux-persist';
import { datagridPageSizeOptions } from './DataGridGeneralOptions';

// const initialState = {
//   searchString: '',
//   columns: [
//     {
//       column: "BLAB",
//       visible: true,
//       pinned: true,
//     }
//   ],
//   sortedBy: [],
//   filter: {
//     groups: []
//   },
// };
const filterInitialState = {
  searchString: '',
  columns: [],
  sortedBy: [],
  sortModel: {},
  filter: {},
  overlayWidth: 0,
};

/**
 * Initial general state
 * Can save general data you want to apply acorss all views
 */
const initialState = {
  viewInstances: [],
  settings: {
    pageSize:datagridPageSizeOptions[0]
  }
};


const gridFilterSlice = createSlice({
  name: 'gridFilter',
  initialState: initialState,
  reducers: {
    updatePageSize: (state,action) => {
      state.settings.pageSize = action.payload;
    },
    createViewInstance: (state,action) => {
      // const {id, initialState} = action.payload;
      state.viewInstances.push({});
    },
    removeViewInstance: (state,action) => {
      state.viewInstances.splice(action.payload,1);
    },
    createFilterInstance: (state,action) => {
      const {index,id} = action.payload;
      state.viewInstances[index][id] = filterInitialState;
    },
    removeFilterInstance: (state,action) => {
        const { index, id } = action.payload;
        delete state.viewInstances[index][id];
    },

    updateOverlayWidth: (state,action) => {
      const { index, id,overlayWidth } = action.payload;
      state.viewInstances[index][id].overlayWidth = overlayWidth;
    },

    updateSort: (state,action) => {
      const { index, id,sortModel } = action.payload;
      state.viewInstances[index][id].sortModel = sortModel;
    },
    
    updateFilter: (state, action) => {
      const { index, id, filterKey, value } = action.payload;
      console.log(action.payload);
    
      if (state.viewInstances[index]?.[id]) {
        // Update the filter object by key
        state.viewInstances[index][id].filter[filterKey] = value;
      }
    },


    // createFilterInstance: (state, action) => {
    //   const { id, initialState } = action.payload;
    //   state.instances[id] = filterInitialState;
    //   state.instanceOrder.push(id); // Assuming instanceOrder is part of your initial state
    // },
    // removeFilterInstance: (state, action) => {
    //   const { id } = action.payload;
    //   delete state.instances[id];
    // },
    // removeLastFilterInstance: (state) => {
    //   if (state.instanceOrder.length > 0) {
    //     const lastId = state.instanceOrder.pop();
    //     delete state.instances[lastId];
    //   }
    // },
    setShGridColumns: (state, action) => {
      const { index,id, columns } = action.payload;
      if (state.viewInstances[index]?.[id]) {
        state.viewInstances[index][id].columns = columns;
      }
    },
    updateShGridColumn: (state, action) => {
      const { index,id, column, visible, pinned,width } = action.payload;
      const instance = state.viewInstances[index][id];
      if (instance) {
        const index = instance.columns.findIndex(col => col.column === column);
        if (index !== -1) {
          instance.columns[index] = {
            ...instance.columns[index],
            ...(visible !== undefined && { visible }),
            ...(pinned !== undefined && { pinned }),
            ...(width !== undefined && { width }),
          };
        }
      }
    },
    setShGridSortedBy: (state, action) => {
      const { index,id, sortedBy } = action.payload;
      if (state.viewInstances[index]?.[id]) {
        state.viewInstances[index][id].sortedBy = sortedBy;
      }
    },
    updateSearch: (state, action) => {
      const { index,id, searchString } = action.payload;
      if (state.viewInstances[index]?.[id]) {
        state.viewInstances[index][id].searchString = searchString;
      }
    },
   
    resetFilter: (state,action) => {
      state = initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(REHYDRATE, (state, action) => {
        // Or if you want to update the state with the rehydrated state:
        if (action.payload) {
          return { ...state, ...action.payload.gridFilter };
        }
        return state;
      })
  }
});

export const {
  updateOverlayWidth,
  createViewInstance,
  removeViewInstance,
  createFilterInstance,
  removeFilterInstance,
  updateSearch,
  resetFilter,
  resetSearchString,
  updateFilter, 
  removeFilterGroup, 
  removeFilterRule,
  options,
  setPreviousRoute,
  setActiveTabChanged,
  setShGridColumns,
  updateShGridColumn,
  setShGridSortedBy,
  updateSort,
  updatePageSize
} = gridFilterSlice.actions;

export default gridFilterSlice;