import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
// import { tokens } from "../../theme";
// import { mockDataContacts } from "../../data/mockData";
import { useTheme } from "@mui/material";
import React, { createElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Subheading from "../text/Subheading";

// import GridFilter from "../components/theme/datagrid/GridFilter";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../../theme";
import GridFilter2 from "./GridFilter2";
import ShGridHeader from "./ShGridHeader";

import cx from 'classnames';
import { useTranslation } from "react-i18next";
import { useDebouncedWindowSize } from '../../../contexts/debouncedWindowsize';
import { useGridFilter } from "../../../contexts/GridFilterContext";
import { datagridPageSizeOptions } from "../../../newapi/global/filter/DataGridGeneralOptions";
import { setShGridColumns, updateFilter, updatePageSize, updateShGridColumn, updateSort } from "../../../newapi/global/filter/gridFilterSlice";
import { DropdownBase } from "../dropdowns/Dropdown";
import { PaginationDropUp } from "./PaginationDropUp";
import ShGridEmpty from "./Status/ShGridEmpty";
import ShGridError from "./Status/ShGridError";
function sleep(duration) {
    return new Promise((resolve) => {
        setTimeout(() => {
        resolve();
        }, duration);
    });
}
function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
} 



const ShDataGrid2 = (props) => {
    if(props.selectable && (typeof props.setSelected === "undefined" && typeof props.setSelectedRows === "undefined")) throw new Error("setSelected not implemented when selectable is set to true");
    // const filterObject = useSelector((state) => state.gridFilter);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const { index,id } = useGridFilter();
    // const filterObject = useSelector((state) => state.gridFilter.viewInstances[index]?.[id] || undefined);
    const filterObject = useSelector((state) => {
        const instance = state.gridFilter.viewInstances[index];
        return instance ? instance[id] : undefined;
      });
      
    const [overlayWidth, setOverlayWidth] = useState(0);

    // MenuStateContext
    const collapsed = useSelector((state) => state.settings.environment.collapsed);

    // const {collapsed} = useContext(MenuStateContext);
    const [bottomInView, setBottomInView] = useState(false);

    // usedebounce
    const { width: windowSize }  = useDebouncedWindowSize(300);
    const mounted = useRef(true);
    const gridRef = useRef();

    const [rowHeight, setRowHeight] = useState(64);
    const [columnHeaderHeight, setColumnHeaderHeight] = useState(32);
    // const [footerHeight, setFooterHeight] = useState(72);
    const [isMobile, setIsMobile] = useState(false); // Track mobile view
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [pinnedColumns, setPinnedColumns] = useState(filterObject?.columns.filter(col => col.pinned) || []);

    const [selectedItems, setSelectedItems] = useState(props.selectedItems ?? []);


    // Step 1: Track screen size using useEffect and update isMobile state
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767); // Update mobile status based on screen size
        };

        handleResize(); // Initialize the status based on initial size
        window.addEventListener('resize', handleResize); // Listen for resize events

        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup on component unmount
        };
    }, []);


    const onSelectionModelChange = (newSelectionModel, external) => {
        setSelectedItems(newSelectionModel);

        if(!external){
            if(typeof props.setSelected !== "undefined" || typeof props.setSelectedRows !== "undefined"){
                if(props.setSelectedRows){
                    // Find corresponding row data
                    const selectedRows = rows.filter((row) => newSelectionModel.includes(row.id));
                    props.setSelectedRows(selectedRows);
                }else {
                    props.setSelected(newSelectionModel);
                }
            }
        }
    }; 

    /**
     * Called from phone, no need for a callback as that state handles it internally
     */
    useEffect(() => {
        onSelectionModelChange(props.selectedItems,true);
    },[props.selectedItems])

    const originalClassNames = useRef(props.columns.map(column => column.cellClassName || ''));

    const getCellClassName = (column, index) => {
        let assetClass = "";
        if (pinnedColumns.some(pinnedColumn => pinnedColumn.field === column.field)) {
            assetClass = "fixed-left";
        }
        return `${originalClassNames.current[index]} ${assetClass}`.trim();
    };

    const pinColumn = (column, dis = true) => {
        setColumnVisibilityModel(prevModel => ({
            ...prevModel,
            [column]: false
        }));

        // // prevents circular dependency
        if(dis){
            dispatch(updateShGridColumn({
                index,
                id,
                column: column,
                pinned:true,
            }));
        }
    };


    const unpinColumn = (column, dis = true) => {
        const columnToUnpin = pinnedColumns.find((col) => col.column === column);
        if (columnToUnpin) {
            setColumnVisibilityModel(prevModel => ({
                ...prevModel,
                [column]: true
            }));

            // //prevents circular dependency
            if(dis){
                dispatch(updateShGridColumn({
                    index,
                    id,
                    column: columnToUnpin.column,
                    pinned: false,
                }));
            }
        }
    };

    const updateColumnVisibility = (column, visible, dis = true) => {
        if(column === "phoneView"){
            setColumnVisibilityModel(prevModel => ({
                ...prevModel,
                [column]: false
            }));

        } else {
            setColumnVisibilityModel(prevModel => ({
                ...prevModel,
                [column]: visible 
            }));
        }
        
    }


    // const applyCustomHeader = (columns, customHeaderComponent) =>
    //     columns.map((column, index) => ({
            
    //         return {
    //             ...column,
    //             cellClassName: getCellClassName(column, index),
    //             renderHeader: (params) => createElement(customHeaderComponent, {
    //                 ...params,
    //                 pinColumn,
    //                 unpinColumn,
    //                 sortable:column.sortable ?? true,
    //                 isPinned: pinnedColumns.some(pinnedColumn => pinnedColumn.column === column.field)
    //             }
    //         }
    //     })

    //     );
        const applyCustomHeader = (columns, customHeaderComponent) =>
            columns.map((column, index) => {
                return {
                    ...column,
                    cellClassName: getCellClassName(column, index),
                    renderHeader: (params) =>
                    createElement(customHeaderComponent, {
                        ...params, // Spread all params as props
                        pinColumn, // Ensure `pinColumn` is available in the scope
                        unpinColumn, // Ensure `unpinColumn` is available in the scope
                        sortable: column.sortable ?? true, // Fallback to `true` if `column.sortable` is undefined
                        isPinned: pinnedColumns.some(pinnedColumn => pinnedColumn.column === column.field), // Check if the column is pinned
                        filterino: column.filter ?? null,
                    })
                };
            });

    const language = useSelector((state) => state.settings.language);
    const [columns,setColumns] = useState(applyCustomHeader(props.columns, ShGridHeader));

    useEffect(() => {
        setColumns(applyCustomHeader(props.columns, ShGridHeader));

        // setColumnVisibilityModel(createModel(window.innerWidth));
        // setScrollOffset(pinnedColumns);
    }, [pinnedColumns,language,filterObject?.sortModel]);

    useEffect(() => {
        setColumnVisibilityModel(createModel(window.innerWidth));
    },[])

    useEffect(() => {
        // Listen for changes in filterObject.columns
        if(filterObject && filterObject.columns){
            filterObject.columns.forEach(col => {

                if (col.pinned) {
                    // If the column is marked to be pinned and is not already pinned
                    pinColumn(col.column,false);
                    // Check if the new width is different from the current width
                    if (typeof col.width === "undefined") {
                        const newWidth = getColWidth(col.column); 
                        dispatch(updateShGridColumn({
                            index,
                            id,
                            column: col.column,
                            width: newWidth,
                        }));
                    }
                    
                } else if (!col.pinned) {
                    // If the column is not marked to be pinned anymore but is currently pinned
                    unpinColumn(col.column,false);

                    //In case of no pin we do the check if we need to hide it
                    updateColumnVisibility(col.column,col.visible,false);
                }
            });
            setPinnedColumns(filterObject.columns.filter(col => col.pinned))
        }
        
    }, [filterObject]);

    useEffect(() => {
        let newWidth = 0;
        pinnedColumns.forEach(col => {
            newWidth = newWidth + col.width;
        })
        setOverlayWidth(newWidth);
    },[pinnedColumns])

    
    // We use props.columns here because pinning a column updates the columns state. 
    // Doing it this way ensures there is only a change when an actual change in the views happens.
    // Ensure it only is set if no filterobject.columns exist. Don't want accidental rewrites
    useEffect(() => {
        if(props.columns){
            if(!filterObject.columns || filterObject.columns.length === 0){
                const columnsWithoutLast = props.columns.slice(0, -1);
                var newColumns = columnsWithoutLast.map(col => ({
                    column: col.field,
                    name: col.headerName,
                    visible: col.visible ?? true, // Default to true if not provided
                    pinned: col.pinned ?? false, // Default to false if not provided
                }));
                dispatch(setShGridColumns({
                    index,
                    id:id,
                    columns:newColumns
                }));
            } 
        }
    },[])


    // const rows = props.rows;
    const [rows, setRows] = useState(props.rows ?? []);
    
    // const searchString = useSelector((state) => state.gridFilter.searchString);

        // Adjust the useSelector call to access the search term for the specific instance
    const searchString = useSelector((state) => {
        // Access the specific instance by ID and then its searchString
        // Fallback to an empty string if the instance or searchString is not found
        return state.gridFilter.viewInstances[index]?.[id]?.searchString || '';
    });

    // Adjust the useSelector call to access the search term for the specific instance
    const sortModel = useSelector((state) => {
        // Access the specific instance by ID and then its searchString
        // Fallback to an empty string if the instance or searchString is not found
        return state.gridFilter.viewInstances[index]?.[id]?.sortModel || '';
    });  
    
    // Adjust the useSelector call to access the search term for the specific instance
    const filter = useSelector((state) => {
        // Access the specific instance by ID and then its searchString
        // Fallback to an empty string if the instance or searchString is not found
        return state.gridFilter.viewInstances[index]?.[id]?.filter || {};
    });  
    

    useEffect(() => {
        console.log("filter",filter);
    },[filter]);

    useEffect(() => {
        // Only dispatch if the sortModel has changed (or if any specific change logic is needed)
        if (props.sortModel !== sortModel) {
            dispatch(updateSort({
                index,
                id,
                sortModel: props.sortModel,
            }));
        }
    }, [props.sortModel]);  // This ensures the effect runs only when props.sortModel or sortModel changes
    

    // Initialize config with props.config or an empty object
    let [config, setConfig] = useState({});


    useEffect(() => {
        // Filter out keys with null, undefined, or empty array values from filter
        const validFilter = Object.fromEntries(
            Object.entries(filter).filter(([key, value]) => 
                value !== null && value !== undefined && 
                !(Array.isArray(value) && value.length === 0)
            )
        );
        // Combine the updates for both searchString and sortModel
        const newConfig = { 
            ...validFilter, 
            searchString: searchString, 
            sort: sortModel 
        };
        setConfig(newConfig);
    }, [searchString, sortModel,filter]);  // Runs when either searchString or sortModel changes
    
    const firstRender = useRef(true);

    useEffect(() => {
        if (filterObject && (props.config && typeof props.config === 'object') && firstRender.current) {
            // Loop through all keys in props.config
            Object.keys(props.config).forEach((key) => {
                const updatedStates = props.config[key]; // The value for each key
    
                // Dispatch the updateFilter action for each key in props.config
                dispatch(updateFilter({
                    index,
                    id,
                    filterKey: key, // The key from config is used as the filterKey
                    value: updatedStates, // The value from config is used as the value
                }));
            });
    
            // After the first run, set firstRender.current to false
            firstRender.current = false;
        }
    }, [filterObject, props.config]); // Runs only when filterObject or props.config changes
    

    // const datagridPageSizeOptions=[20,50,100];
    const [pageSize, setPageSize] = useState(useSelector(state => state.gridFilter.settings.pageSize) ?? datagridPageSizeOptions[0]); //this


    const [itemsLoaded, setItemsLoaded] = useState();
    const [totalItems, setTotalItems] = useState();
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState();

    
    // State to manage the pagination model (page and page size)
    const [paginationModel, setPaginationModel] = useState({
        pageSize: datagridPageSizeOptions[0],
        page: 0,  // Starting page is 0 (DataGrid is 0-indexed)
    });

    const handlePageSizeUpdate = (selected) => {
        setPageSize(datagridPageSizeOptions[selected]);
        dispatch(updatePageSize(datagridPageSizeOptions[selected]))
    }

    useEffect(() => {
        // Whenever pageSize is updated, sync it with paginationModel
        setPaginationModel(prev => ({
            ...prev,
            pageSize: pageSize, // Set the new page size
            page: 0, // Reset to page 0 whenever pageSize changes
        }));
    }, [pageSize]);

    const [invalidateCache,setInvalidateCache] = useState(true);

    const [itemId, setItemId] = useState(props.itemId ?? null);
    // console.log(typeof props.content);
    useEffect(() => {
        setItemId(props.itemId);
    },[props.itemId])

    const [initialized, setInitialized] = useState(false);
    
    
    // const queryArgs = useMemo(() => ({
    //     ...(itemId !== null && { id: itemId }),
    //     page: activePage,
    //     size: pageSize,
    //     body: config,
    // }), [itemId, activePage, pageSize, config]); // Dependencies of the query args

    // // useEffect(() => {
    // //     // console.log("Component rendered with queryArgs:", queryArgs);
    // // }, [queryArgs]);  // Logs whenever queryArgs changes

    // // Custom wrapper for props.content
    // const trackContentCall = useCallback((args, options) => {
    //     // console.log('Calling props.content with args:', args, 'options:', options);

    //     // Now call the actual props.content function
    //     return props.content(args, options);
    // }, [props.content]);

    const {
        data,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
        refetch
    } =  props.content({
        args: {
            ...(itemId !== null && { id: itemId }),  // Add itemId if it's not null
            page: activePage,
            size: pageSize,
            body: config,  // Directly use the latest `config`
        },
        invalidate: invalidateCache,  // Include invalidateCache if necessary
    }, { skip: !pageSize || pageSize < 0 });

      // Define a function to generate skeleton rows
    const generateSkeletonRows = () => {
        // Return an array of rows that are clearly marked as skeletons
        // For example, each row could have a `skeleton: true` property
        return [...Array(7)].map((_, index) => ({ id: `skeleton-${index}`, skeleton: true }));
    };

    useEffect(() => {
        if(isFetching) {
            setRows(generateSkeletonRows());
        } else {
            if (data) {

                setRows(data.data);
                // setRows(rows => {
                //     console.log("hewwo",data.data);
                //     return rows.concat(data.data);
                // });
                setItemsLoaded(amount => {
                    return amount ?? 0 + data?.data?.length ?? 0;
                })
                setTotalItems(data.totalItems);
                setTotalPages(data.totalPages);
                if(!initialized) {
                    setInitialized(true);
                }
            } else {
                setRows([]);
            }
        }
    }, [data, isFetching]); // Watch for changes in data

    const [paginationPages, setPaginationPages] = useState([])
    useEffect(() => {
        const pages = [];

        // Calculate the pages to display
        if (activePage > 4) {
            // Display the pages before the active page
            for (let i = Math.max(1, activePage - 3); i <= Math.min(totalPages, activePage); i++) {
                pages.push(i);
            }
        } else {
            // Display the first 5 pages
            for (let i = 1; i <= Math.min(4, totalPages); i++) {
                pages.push(i);
            }
        }
        
        setPaginationPages(pages);
    
    },[totalPages,activePage]);


    const pages = useMemo(() => {
        return Array.from({ length: totalPages }, (_, index) => index + 1);
        // return Array.from({ length: totalPages + 1 }, (_, index) => index);
    }, [totalPages]);

    //CODE IS FOR DEBUG PURPOSES
    // useEffect(() => {
    //     console.log('isLoading:', isLoading, 'isFetching:', isFetching);
    // }, [isLoading, isFetching]);
    
    // useEffect(() => {
    //     if (isLoading) {
    //         console.log('Query is loading for the first time');
    //     }
    //     if (isFetching && !isLoading) {
    //         console.log('Query is fetching (not loading for the first time)');
    //     }
    //     if (!isFetching && !isLoading) {
    //         console.log('Query has completed and is not fetching');
    //     }
    // }, [isLoading, isFetching]);

//     const prevArgs = useRef({ itemId, activePage, pageSize, config, invalidateCache });

//     useEffect(() => {
//         const changes = {};
//         if (prevArgs.current.itemId !== itemId) changes.itemId = { from: prevArgs.current.itemId, to: itemId };
//         if (prevArgs.current.activePage !== activePage) changes.activePage = { from: prevArgs.current.activePage, to: activePage };
//         if (prevArgs.current.pageSize !== pageSize) changes.pageSize = { from: prevArgs.current.pageSize, to: pageSize };
//         if (prevArgs.current.invalidateCache !== invalidateCache) changes.invalidateCache = { from: prevArgs.current.invalidateCache, to: invalidateCache };
//         // Assuming deep equality check for config object
//         if (JSON.stringify(prevArgs.current.config) !== JSON.stringify(config)) changes.config = { from: prevArgs.current.config, to: config };

//         if (Object.keys(changes).length > 0) {
//             console.log(props.title, 'Refetch triggered by changes:', changes);
//             prevArgs.current = { itemId, activePage, pageSize, config, invalidateCache };
//         }
//     }, [itemId, activePage, pageSize, config, invalidateCache]);

// // Add logging for re-renders
// useEffect(() => {
//     console.log('Component re-rendered');
// }, []); // This logs once on initial mount

    useEffect(() => {
        return () => {
            mounted.current = true;
          };
    }, []);


    useEffect(() => {
        if(initialized && bottomInView && itemsLoaded !== totalItems) {
            setInvalidateCache(false);
            setActivePage((page) => {
                return page+=1;
            });
        }
    }, [bottomInView])
    
    //Mapping field data
    const columnDataFieldMap = columns.reduce((acc, col) => {
        acc[col.field] = col.dataField || col.field;
        return acc;
      }, {});

    const createModel = (windowSize) => {
        let model = {};
        model["phoneView"] = false;
        if(typeof rows !== "undefined" && rows.length > 0) {
            for(let i = 0; i < pinnedColumns.length; i++) {
                model[columnDataFieldMap[pinnedColumns[i].column]] = false;
            }
        } 
        return model;
    }
    

    const [columnVisibilityModel, setColumnVisibilityModel] = useState(createModel(window.innerWidth));

    const prevPage = () => {
        if(activePage > 1) {
            setInvalidateCache(false);
            setActivePage((page) => {
                return page -=1
            });
        }
    }
    const nextPage = () => {
        if(activePage < totalPages) {
            setInvalidateCache(false);
            setActivePage((page) => {
                return page+=1;
            });
        }
    }

    const selectPage = (page) => {
        setInvalidateCache(false);
        setActivePage(page);
    }
    
    const [fixedHeader, setFixedHeader] = useState(false);
    const refContainer = useRef(null);
  
    const checkScrollPosition = () => {
      if (refContainer.current) {
        const topPosition = refContainer.current.getBoundingClientRect().top - 72;
        setFixedHeader(topPosition <= 0);
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', checkScrollPosition);
  
      return () => {
        window.removeEventListener('scroll', checkScrollPosition);
      };
    }, []);

    const colRefs = useRef(null);

    const getColWidth = (column) => {
        if (colRefs.current) {
            const gridRoot = colRefs.current;
            const columnElements = gridRoot.querySelectorAll('.MuiDataGrid-columnHeader');
            const targetColumn = Array.from(columnElements).find(el => el.getAttribute('data-field') === column);
            if (targetColumn) {
                return targetColumn.offsetWidth;
            }
           
        }
        return -1;
    }

    const [hoveredRowId, setHoveredRowId] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);

    //Get value based on field data path
    const getNestedValue = (obj, path) => {
        if(typeof path !== "undefined"){
            return path.split('.').reduce((acc, part) => acc && acc[part], obj);
        }
        return "";
    }

    const getColDef =(pinnedColField) => {
        for(var i = 0; i < columns.length; i++) {
            if(columns[i].field === pinnedColField) {
                return {
                    def: columns[i],
                    sortable:columns[i].sortable ?? true
                };
            }
        }
    }

    const setScrollOffset = () => {
        if(colRefs.current) {
            const gridRoot = colRefs.current;
            const extraPadOffset = pinnedColumns.length > 0 ? 16 : 0;
            
            //Content body
            const contentRenderZone = gridRoot.querySelectorAll(".MuiDataGrid-virtualScrollerRenderZone");
            contentRenderZone.forEach(element => {
                element.style.cssText = `margin-left:${overlayWidth + extraPadOffset}px;`;
            });

            //Content header
            const headerZone = gridRoot.querySelectorAll(".MuiDataGrid-columnHeadersInner");
            headerZone.forEach(element => {
                element.style.cssText = `margin-left:${overlayWidth + extraPadOffset}px;`;
            })
        }
    }

    const handleMouseEnter = (event) => {
        setHoveredRowId(event.target.getAttribute("data-id"));
    }
    const handleMouseLeave = () => {
        setHoveredRowId(null);
    }

    const onRowClick = (row) => {
        if(!row.row.skeleton){
            setSelectedRowId(row.id);
            if(typeof props.onRowClick !== "undefined"){
                props.onRowClick(row);
            }
        }
    }

    useEffect(() => {
        setScrollOffset();
    },[overlayWidth])
    
    // useEffect(() => {
    //     if (colRefs.current) {
    //         // alert("owo");
    //         const rows = colRefs.current.querySelectorAll('.MuiDataGrid-row');
    //         rows.forEach(row => {
    //             row.addEventListener('mouseenter', handleMouseEnter);
    //             row.addEventListener('mouseleave', handleMouseLeave);
    //             // row.addEventListener('click',handleClick);
    //         });
    //     };


    //     return () => {
    //         if (colRefs.current) {
    //             const rows = colRefs.current.querySelectorAll('.MuiDataGrid-row');
    //             rows.forEach(row => {
    //                 row.removeEventListener('mouseenter', handleMouseEnter);
    //                 row.removeEventListener('mouseleave', handleMouseLeave);
    //                 // row.addEventListener('click',handleClick);
    //             });
    //         }
    //     };
    // }, [colRefs.current, pinnedColumns]);

    const GridFilter2Memoized = React.memo(GridFilter2);

    const getRowsClassName = (params) => {
        if(props.isRowSelectable && !props.isRowSelectable(params.row)) {
            return "no-checkbox"
        }

    }
    const isRowSelectable = (params) => {
        if(props.isRowSelectable && !props.isRowSelectable(params.row)) {
            return false;
        }
        return true;
    }
    
    
    return (
        <Box ref={refContainer} height={1} display={"flex"} flexDirection={"column"}>
            
            {((props.title) || !(props.gridOptions.hide)) &&
                <Box
                    display="flex" 
                    justifyContent="space-between" 
                    backgroundColor={theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]} 
                    alignItems="center" 
                    paddingX={3} 
                    paddingY={3}
                    sx={
                        fixedHeader ?
                        {
                            position:"fixed",
                            width:collapsed ? 1 : "calc(100% - 342px)",
                            left:collapsed ? 0 : 342,
                            top:72,
                            background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                            zIndex:300,
                            borderTopLeftRadius:24,
                            borderTopRightRadius:24,
                            transition:"left 500ms",
                            "&:before" : {
                                content: "''",
                                display:"block",
                                width:"32px",
                                height:"32px",
                                position:"absolute",
                                left:0,
                                top:0,
                                zIndex:1,
                                backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                                top:"0px",
                                height:"48px",
                                width:"24px",
                                borderTopLeftRadius:"24px",
                                boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                                // transition:"250ms"
                            },

                            "&:after" : {
                                content: "''",
                                display:"block",
                                width:"32px",
                                height:"32px",
                                position:"absolute",
                                top:0,
                                zIndex:1,
                                backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                                top:"0px",
                                right:0,
                                height:"48px",
                                width:"24px",
                                borderTopLeftRadius:"24px",
                                boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                                transform:"scaleX(-1)",
                                // transition:"250ms"
                            },
                            "@media screen and (max-width: 85.375em)" : {
                                width:1,
                                left:0,
                            },
                            "@media screen and (max-width:767px)" : {
                                display:"none"
                            }
                        } : {
                            "@media screen and (max-width:767px)" : {
                                display:"none"
                            }
                        }
                    }
                >
                    {props.title !== undefined && props.title !== "" && 
                        <Subheading
                            sx={{
                            }}
                        >
                            {props.title}

                            {typeof props.explainer !== "undefined" && props.explainer !== null ? 
                                props.explainer
                            : 
                                null
                            }
                        </Subheading>
                    }
                    {props.gridOptions.hide === "undefined" || !props.gridOptions.hide ?
                        <GridFilter2Memoized 
                            gridOptions={props.gridOptions} 
                            gridActions={props.gridActions}
                        >
                        </GridFilter2Memoized>
                    : null
                    }
                </Box>
            }

            <Box
                className={cx('grid-pinned-cols',{
                    'is-fixed' : fixedHeader,
                })}
                ref={gridRef}
                m="0 24px 0 24px" 
                paddingTop={fixedHeader ? "120px" : null}
                flex={1}
                sx={{
                    pb:16,
                    // display:"flex",
                    position:"relative",

                    "@media screen and (max-width: 47.937em)" : {
                        margin: "0 !important",
                    },

                    "& .MuiDataGrid-cell": {
                        // borderBottom: "none !important",
                        borderBottom: `1px solid ${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]} !important`,
                        color: `${colors.txt["primary"]} !important`,
                        fontWeight: 400,
                        "& .MuiCheckbox-root": {
                            paddingLeft:0,
                            color:`${theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.grey[300]}`,
                            "&.Mui-checked" : {
                                color: `${theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400]}`
                            }
                        },
                    },
                    "& .MuiDataGrid-cell:focus": {
                        outlineColor: "transparent !important"
                    },
                    "& .name-column--cell": {
                        // color: colors.greenAccent[300]
                    },
                    "& .MuiDataGrid-toolbarContainer": {
                        height:0,
                        padding:0,
                        opacity: 0,
                        display:"none"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        
                        // borderBottom: "none !important",
                        height:'unset !important',
                        minHeight:'unset !important',
                        lineHeight:'unset !important',
                        maxHeight:'unset !important',
                        borderBottom: `1px solid ${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]} !important`,
                        // height: `${columnHeaderHeight}px !important`,
                        // minHeight: `${columnHeaderHeight}px !important`,
                        // lineHeight: `${columnHeaderHeight}px !important`,
                        // maxHeight: `${columnHeaderHeight}px !important`,
                        "& .MuiDataGrid-columnHeaderTitleContainerContent" :{
                            width:1,
                            "& .MuiCheckbox-root" : {
                                // paddingLeft:0,
                                color:`${theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.grey[300]}`,
                                "&.Mui-checked" : {
                                    color: `${theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400]}`
                                },
                                "&:focus" : {
                                    outline:"hidden"
                                }
                                // background:"orange"
                            }
                        }
                    },
                    "&.is-fixed .MuiDataGrid-columnHeaders": {
                        position:'fixed !important',
                        top:160,
                        zIndex:300,
                        background:theme.palette.mode === "dark" ? colors.grey[200]: colors.grey[0],
                    },
                    "& .MuiDataGrid-columnHeader": {
                        // backgroundColor:"orange !important",
                        color: `${colors.txt["secondary"]} !important`,
                        height: `${columnHeaderHeight}px !important`, 
                        minHeight: `${columnHeaderHeight}px !important`,
                        lineHeight: `${columnHeaderHeight}px !important`,
                        maxHeight: `${columnHeaderHeight}px !important`,
                        "&:focus-within" : {
                            outline:"none !important",
                        },
                        "&:focus-visible": {
                            outline:`solid ${colors.primary[400]} !important`,
                            outlineWidth:"1px !important"
                        }
                    },
                    "& .MuiDataGrid-columnHeader--sorted" : {
                        color: `${colors.txt["primary"]} !important`,
                    },
                    "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-columnHeaderTitle" : {

                        fontWeight:"500 !important"
                    },
                    "& .MuiDataGrid-columnHeader:focus" : {
                        outlineColor: "transparent !important"
                    },
                    "& .MuiDataGrid-columnHeaderTitle" : {
                        fontWeight: 400
                    },
                    "& .MuiDataGrid-root" : {
                        border:"none",
                        zIndex:1,
                    },
                    "& .MuiDataGrid-virtualScroller" : {
                        display:"flex"
                    },
                    "&.is-empty .MuiDataGrid-virtualScroller, &.is-loading .MuiDataGrid-virtualScroller" : {
                        overflow:'hidden',
                        // background:"orange",
                        // marginTop: "32px !important",
                    },
                    "&.is-loading .MuiDataGrid-virtualScroller .MuiDataGrid-overlayWrapper" : {
                        display:"none"
                    },
                    " .MuiDataGrid-row" : {
                        cursor:"pointer",
                        /** Classes */
                        "&.Mui-selected":{
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]} !important`
                        },
                        "&:hover":{
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]} !important`
                        },
                        "& .MuiDataGrid-cell": {
                            "&.fixed-left" : {
                                visibility:"hidden",
                                opacity:0,
                                background:"orange",
                                position:"sticky",
                                left:"0"
                            }
                        }
                    },

                    "& .MuiDataGrid-footerContainer": {
                        display:"none",
                        borderTop: "none",
                        // backgroundColor: colors.blueAccent[700]
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`
                    },
                    "& .MuiDataGrid-columnSeparator, & .MuiDataGrid-menuIcon, & .MuiDataGrid-iconButtonContainer" : {
                        display:"none !important"
                    },
                    "@media sceen and (min-width:47.9375em)" : {
                    
                        " .phone-column--cell": {
                            display:"none !important",
                        },   
                    },
                    ".MuiDataGrid-columnHeader[aria-label=PhoneView]" : {
                        background:"blue",
                        // display:"none"
                    },
                    ".MuiDataGrid-overlayWrapper" :{
                        minHeight:300,
                        ".MuiDataGrid-overlayWrapperInner" : {
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                        },
                    },
                    "@media screen and (max-width: 47.9375em)" : {
                        "& .MuiDataGrid-root" : {
                            height:"100% !important",

                            "& .MuiDataGrid-main" : {
                                height:"100% !important",
                                overflow:"unset !important",

                                "& .MuiDataGrid-columnHeaders": {
                                    // borderBottom: "none !important",
                                        display:"none"
                                },

                                " > div:nth-of-type(2)" : {
                                    height:"100% !important",
                                    width: "100% !important"
                                }
                            }
                        },
                        "& .MuiDataGrid-virtualScroller" : {
                            height:"100% !important",
                            overflowY:"auto !important",
                            marginTop: "0px !important",

                            "& .MuiDataGrid-virtualScrollerContent" : {
                                height:"100% !important",
                                width: "100% !important",

                                "& .MuiDataGrid-virtualScrollerRenderZone" : {
                                    gap:"16px",
                                    padding: "24px !important",
                                    width:1,

                                    "& .MuiDataGrid-row" : {
                                        flexWrap:"wrap !important",
                                        maxHeight: "unset !important",
                                        minHeight: "unset !important",
                                        width:"100% !important",
                                        borderRadius:2,

                                        "& .MuiDataGrid-cell": {
                                            maxWidth: "unset !important",
                                            maxHeight: "unset !important",
                                            minHeight: "unset !important",
                                            minWidth: "unset !important",
                                            display: "none",
                                            padding:0,
                                            // "& .MuiCheckbox-root": {
                                            //     paddingLeft:0,
                                            //     color:"orange",
                                            // },
                                        }
                                    },
                                }
                            }
                        },
                        "& .MuiDataGrid-footerContainer": {
                            // display:"none"
                        }
                    },
                                            
                    ".phone-column--cell" : {
                        display:"block !important",
                        width:"100% !important",
                        borderBottom: "none !important",
                        overflow:"visible !important"
                    },
                    "@media screen and (min-width:47.9375em)" : {
                    
                        ".phone-column--cell": {
                            // display:"none !important",
                        },   
                    },
                    ".no-checkbox .MuiDataGrid-checkboxInput" : {
                        display: "none"
                    },
                    ...props.gridOptions?.styles?.root
                    // ".MuiDataGrid-columnHeader[data-field='phoneView']" : {
                    //     display:"none !important"

                    // }
                }}


            >
                {pinnedColumns.length > 0 ?
                    <Box
                        className={cx('',{
                            'is-fixed':fixedHeader
                        })}
                        sx={{
                            zIndex:2,
                            position:"absolute",
                            // background:"blue",
                            top:fixedHeader ? 88 : 0,
                            left:0,
                            height:`${fixedHeader ? "calc(100% - 88px)" : 1}`,
                            width:`${overlayWidth}px`,
                            zIndex:200,
                            display:"flex",
                            alignItems:"flex-start",
                            justifyContent:"flex-start",
                            flexDirection:"column",
                            boxShadow: `2px 0px 19px 0px ${theme.palette.mode === "dark" ? "rgba(255,255,255,.05)" : "rgba(49, 65, 95, 0.15)"}`,
                            "&:before" : {
                                content: "''",
                                width:"calc(100% + 16px)",
                                height: "calc(100% + 16px)",
                                top:-16,
                                left:-16,
                                background:"transparent",
                                position:"absolute",
                                borderLeft:`16px solid ${theme.palette.mode === "dark" ?colors.grey["200"] : colors.grey["0"]}`,
                                borderTop:`16px solid ${theme.palette.mode === "dark" ?colors.grey["200"] : colors.grey["0"]}`,
                                zIndex:-1
                            },
                            "&:after" : {
                                content: "''",
                                width:"calc(100% + 16px)",
                                top:-16,
                                height: 16,
                                // bottom:-16,
                                // left:-16,
                                background:"transparent",
                                position:"absolute",
                                zIndex:1,
                                borderLeft:`16px solid ${theme.palette.mode === "dark" ?colors.grey["200"] : colors.grey["0"]}`,
                                borderTop:`16px solid ${theme.palette.mode === "dark" ?colors.grey["200"] : colors.grey["0"]}`
                            },
                            "&.is-fixed:after": {
                                display:"none",
                            }
                        }}
                    >
                        <Box
                            className={cx('',{
                                'is-fixed':fixedHeader
                            })}
                            sx={{
                                display:"flex",
                                "&.is-fixed": {
                                    position:'fixed !important',
                                    top:160,
                                    zIndex:301,
                                    background:'white',
                                },
                            }}
                        >
                            {pinnedColumns.map(pinned => {
                                const col = getColDef(pinned.column);
                                console.log("asdsa",col);
                                return(
                                    <Box
                                        sx={{
                                            minWidth: pinned.width,
                                            maxWidth: pinned.width,
                                            background:theme.palette.mode === "dark" ?colors.grey["200"] : colors.grey["0"]
                                        }}
                                        key={pinned.column}
                                    >
                                        <Box
                                            sx={{
                                                background:theme.palette.mode === "dark" ?colors.grey["200"] : colors.grey["0"],
                                                height: `${columnHeaderHeight}px !important`,
                                                minHeight: `${columnHeaderHeight}px !important`,
                                                lineHeight: `${columnHeaderHeight}px !important`,
                                                maxHeight: `${columnHeaderHeight}px !important`,
                                                borderBottom: `1px solid ${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]} !important`,
                                                color: `${colors.txt["secondary"]} !important`,
                                                fontWeight: 400,
                                            }}
                                        >
                                            <ShGridHeader 
                                                isPinned 
                                                unpinColumn={unpinColumn} 
                                                pinColumn={pinColumn} 
                                                colDef={col.def}
                                                sortable={col.sortable}
                                                filterino={col.def.filter ?? null}
                                            />
                                        
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Box>
                        <Box
                            className={cx('',{
                                'is-fixed':fixedHeader
                            })}
                            sx={{
                                "&.is-fixed": {
                                    pt:4,
                                }
                            }}
                        >
                            {
                                rows.map((row,index) => {

                                    return (
                                        <Box
                                            data-id={row.id}
                                            className={cx('SH_PinRow', {
                                                'sh_pin_row_hovered': hoveredRowId == row.id,
                                                'sh_pin_row_selected': selectedRowId == row.id,
                                            })}
                                            // className={"SH_PinRow"}
                                            sx={{
                                                display:"flex",
                                                background:theme.palette.mode === "dark" ?colors.grey["200"] : colors.grey["0"],
                                                position:"relative",
                                                "&:after" : {
                                                    content:"''",
                                                    position:"absolute",
                                                    right:-16,
                                                    height:1,
                                                    width:16,
                                                    // zIndex:2
                                                    zIndex:-1

                                                },
                                                "&.sh_pin_row_hovered, &.sh_pin_row_selected" : {
                                                    // background:"blue"
                                                    backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]} !important`,
                                                    boxShadow: `2px 0px 19px 0px ${theme.palette.mode === "dark" ? "rgba(255,255,255,.05)" : "rgba(49, 65, 95, 0.15)"}`,
                                                    "&:after" : {
                                                        backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]} !important`,
                                                    }
                                                },
                                            }}
                                            key={index + "mui-pin-row"}
                                        >
                                            {pinnedColumns.map(pinned => {
                                                const colDef = columns.find(col => col.field === pinned.column);
                                                const cellValue = getNestedValue(row, columnDataFieldMap[pinned.column]);
                                                const renderCellParams = {
                                                    id: row.id,
                                                    field: pinned.column,
                                                    value: cellValue,
                                                    row: row,
                                                    colDef: colDef,
                                                    cellMode: 'view',
                                                    isEditable: false, // Assuming non-editable in this context
                                                    hasFocus: false,
                                                    tabIndex: -1,
                                                    // getValue: (id, field) => /* Implement logic to get value */,
                                                    api: null, // You might not have access to the full Grid API
                                                    cellElement: null, // Not available in this context
                                                    formattedValue: cellValue, // Assuming no formatting
                                                    isCellEditable: () => false,
                                                };
                                                return (
                                                    <Box
                                                        key={"sh_row_col"+pinned.column+row.id}
                                                        sx={{
                                                            minWidth: pinned.width,
                                                            maxWidth: pinned.width,
                                                            height:rowHeight,
                                                            display:"flex",
                                                            alignItems:"center",
                                                            color: `${colors.txt["primary"]}`,
                                                            fontWeight: 400,
                                                            overflow:"hidden",
                                                            whiteSpace:"nowrap",
                                                            borderBottom: `1px solid ${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]} !important`,
                                                        }}
                                                    >
                                                        {colDef && colDef.renderCell ? colDef.renderCell(renderCellParams) : cellValue}
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    
                    </Box>
                : null }

                {!isMobile ?
                    !isError ? 
                        <DataGrid
                            ref={colRefs}
                            pageSizeOptions={datagridPageSizeOptions}
                            // apiRef={props.apiRef}
                            // rows={[]}
                            onRowClick={onRowClick}
                            rows={rows}
                            // onSortModelChange={customSort}
                            columns={columns}
                            // components={{ Toolbar: GridToolbar}}
                            loading={isLoading}
                            // autoHeight
                            disableSelectionOnClick
                            disableColumnMenu

                            sortingOrder={[]} // Make sure sorting order is empty, preventing sorting
                            sortModel={[]} // Ensure there is no model for sorting
                            onSortModelChange={() => {}} // Disable the sortModelChange handler

                            pageSize={paginationModel.pageSize} // Pass pageSize directly
                            paginationModel={paginationModel} // Pass paginationModel
                            onPaginationModelChange={(model) => setPaginationModel(model)} // Update paginationModel on changes
                            
                            disableMultipleColumnsFiltering={false}
                            rowHeight={rowHeight}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>{
                                setColumnVisibilityModel(newModel)
                            }}
                            getRowClassName={getRowsClassName}
                            isRowSelectable={isRowSelectable}
                            checkboxSelection={props.selectable}
                            // onSelectionModelChange
                            onRowSelectionModelChange={(newSelectionModel) => {
                                onSelectionModelChange(newSelectionModel,false);
                            }}
                            rowSelectionModel={selectedItems}
                            
                            slots={{
                                noRowsOverlay:() => !isLoading && !isFetching && <ShGridEmpty/>
                            }}
                        />
                    :
                        <ShGridError/>
                : 
                rows?.length === 0 && !isLoading && !isFetching ? 
                    <ShGridEmpty/> 
                :
                    <Box
                        sx={{
                            display:"flex",
                            flexDirection:"column",
                            gap:2,
                            px:3,
                            pt:3,
                            ...props.gridOptions?.styles?.['mobile-root']
                        }}
                    >
                        {rows.map((row,rowIndex) => (
                            columns.filter((col) => col.field === 'phoneView').map((col) => {
                                const params = {
                                    value: row[col.field], // The value for this cell
                                    row, // Full row data
                                    field: col.field, // Field name
                                    colDef: col, // Column definition
                                    rowIndex, // Index of the row
                                    isEditable: col.isEditable ?? false, // Editable status (use your logic)
                                    hasFocus: false, // (use your logic if you need to check focus)
                                    api: {}, // If needed, pass the grid API (you can leave empty for now)
                                    getValue: (field) => row[field], // Function to get value for a specific field
                                    isSelected: false, // Whether the row is selected (use your logic)
                                };
                                return (
                                    <Box key={col.field}>
                                        {col.renderCell(params)}
                                        {/* {console.log(col.renderCell(params))} */}
                                    </Box>
                                )
                            })
                        ))}
                    </Box>
                }
                
                {/* <BottomInView bottomInView={bottomInView} setBottomInView={setBottomInView} /> */}
                
            </Box>


            {/* Botttombar */}
            {rows?.length > 0 &&
                <Box 
                    className={cx('sh-grid-bottom-bar',{
                        'sidepanel':props.isSidepanelGrid,
                        'collapsed':collapsed,
                        'dark':theme.palette.mode === "dark"
                    })}
                    sx={{
                        background:theme.palette.mode === "dark" ? colors.grey[500]  : "rgba(255,255,255,.75)",
                        backdropFilter:"blur(8px)",
                        boxShadow:"0px 0px 32px rgba(49,65,95,.45)",
                        position:'fixed',
                        zIndex:200000,
                        left: "calc(50% + 342px)",
                        transform:"translateX(calc(-50% - 171px))",
                        bottom:32,
                        
                        display:"flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        height:56,
                        borderRadius:7,
                        transition:"500ms",
                        "&.sidepanel": {
                            left:"unset",
                            right:468,
                            transform:"translateX(50%)",
                        },
                        "&.collapsed": {
                            left:"50%" ,
                            transform: "translateX(-50%)" ,
                            transition:"500ms",

                        },
                        "@media screen and (max-width: 85.5em)" : {
                            left:"50%" ,
                            transform: "translateX(-50%)" ,
                        },
                        "@media screen and (max-width: 63.9375em)" : {
                            "&.sidepanel": {
                                right:"unset",
                                left:"50%",
                                transform:"translateX(-50%)",
                            },
                        },
                        "@media screen and (max-width: 47.9375em)" : {
                            display:"none"
                        }
                    }}
                
                >
                    <Box 
                        sx={{
                         
                            display:"flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            height:56,
                            gap:1,
                            transition:"500ms",
                            pr:2,
                           
                        }}
                    >
                        {/* {Math.ceil(tota)} */}
                        {/* <Text>12 - 1200</Text> */}
                        <Box
                        component={"button"}
                        type="button"
                            className="active"
                            sx={{
                                height:40,
                                width:40,
                                display:"flex",
                                alignItems:"center",
                                borderRadius:12,
                                color: colors.txt["secondary"],
                                position: "relative",
                                cursor: "pointer",
                                userSelect:"none",
                                transition:"250ms",
                                border:"none",
                                background:"transparent",
                                fontWeight:500,

                                "& .prev_icon" : {
                                    position: "absolute",
                                    left:18,
                                },

                                "&:hover" : {
                                    // background: colors.grey[50],
                                    background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],
                                    transition:"250ms",

                                },
                                "&:focus" : {
                                    background:"transparent"
                                },
                                "@media screen and (max-width: 47.9375em)" : {
                                    width:48,
                                    height:48
                                }
                            }}
                            onClick={() => prevPage()}
                        >
                            <ArrowBackIos className="prev_icon" fontSize="14"/>
                        </Box>

                        {/* {activePage}&nbsp;
                        {totalPages}&nbsp;
                        {pageSize} */}

                        {paginationPages.map((page) => (
                            page !== totalPages &&
                            <Box
                            key={"paginationPage"+page}
                            component={"button"}
                            type="button"
                                className={cx("",{
                                    'active': parseInt(activePage) === parseInt(page)
                                })}
                                sx={{
                                    height:40,
                                    minWidth:40,
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    borderRadius:12,
                                    color: colors.txt["primary"],
                                    position: "relative",
                                    cursor: "pointer",
                                    userSelect:"none",
                                    transition:"250ms",
                                    border:"none",
                                    background:"transparent",
                                    px:1,
                                    fontSize:16,
                                    fontFamily:"Roboto, sans-serif",

                                    "&.active": {
                                        background:theme.palette.mode === "dark" ? colors.pastel.light.blue[100] : colors.primary[100],
                                        color: theme.palette.mode ==="dark" ? "#000" : null,
                                        fontWeight:700,
                                    },
        
                                    "&:hover" : {
                                        //   background: colors.grey[100],
                                        background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],
                                        transition:"250ms",
        
                                    },
                                    "&.active:hover": {
                                        // background:theme.palette.mode === "dark" ? colors.blue[100] : colors.primary[100],
                                        background:theme.palette.mode === "dark" ? colors.pastel.light.blue[100] : colors.primary[100],
                                        color: theme.palette.mode ==="dark" ? "#000" : null,
                                        fontWeight:700,
                                    },
                                    
        
                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:48,
                                        height:48
                                    }
                                }}
                                onClick={() => selectPage(page)}
                            >
                                {page}
                            </Box> 
                                
                        ))}

                        {activePage < totalPages-1 && 
                        totalPages> 5 &&
                            <Box
                            component={"button"}
                            type="button"
                                className={cx("",{
                                'active': parseInt(activePage) === parseInt(paginationPages[paginationPages.length-1]+1)
                                })}
                                sx={{
                                    height:40,
                                    minWidth:40,
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    borderRadius:12,
                                    color: colors.txt["primary"],
                                    position: "relative",
                                    cursor: "pointer",
                                    userSelect:"none",
                                    transition:"250ms",
                                    border:"none",
                                    background:"transparent",
                                    px:1,
                                    fontSize:16,
                                    fontFamily:"Roboto, sans-serif",

                                    "&.active": {
                                        background:theme.palette.mode === "dark" ? colors.pastel.light.blue[100] : colors.primary[100],
                                        color: theme.palette.mode ==="dark" ? "#000" : null,
                                        fontWeight:700,
                                    },

                                    "&:hover" : {
                                        // background: colors.grey[100],
                                        background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],
                                        transition:"250ms",

                                    },
                                    "&.active:hover": {
                                        background:theme.palette.mode === "dark" ? colors.pastel.light.blue[100] : colors.primary[100],
                                        color: theme.palette.mode ==="dark" ? "#000" : null,
                                        fontWeight:700,
                                    },
                                    

                                    "@media screen and (max-width: 47.9375em)" : {
                                        width:48,
                                        height:48
                                    }
                                }}
                                onClick={() => selectPage(paginationPages[paginationPages.length-1]+1)}
                            >
                            {paginationPages.length > 0 ? paginationPages[paginationPages.length-1]+1 : ""}
                            </Box> 
                        }

                        {totalPages> 5 &&
                            <PaginationDropUp
                                amount={totalPages}
                                onChange={(selected) => selectPage(selected)}
                                selected={activePage}
                                noSelection={t("no_label")} 
                                useTranslation 
                                closeOnSelection
                                disallowDeselection
                                // dropUp
                                transformOrigin={{
                                    vertical: "bottom"
                                }}
                                anchorOrigin={{
                                    vertical:"top"
                                }}
                            >
                                <Box
                                    className="active"
                                    sx={{
                                        height:40,
                                        minWidth:40,
                                        display:"flex",
                                        alignItems:"center",
                                        justifyContent:"center",
                                        borderRadius:12,
                                        color: "#fff",
                                        position: "relative",
                                        cursor: "pointer",
                                        userSelect:"none",
                                        transition:"250ms",
                                        border:"none",
                                        background:theme.palette.mode === "dark" ? colors.grey[300] :  colors.txt.primary,
                                        pl:1,
                                        pr:1,
                                        fontWeight:"bold",
                                        
            
                                        "& .prev_icon" : {
                                            position: "absolute",
                                            left:12
                                        },
            
                                        "&:hover" : {
                                            background: theme.palette.mode === "dark" ? colors.grey[200] : "#000",
                                            transition:"250ms",
            
                                        },
                                        
            
                                        "@media screen and (max-width: 47.9375em)" : {
                                            width:48,
                                            height:48
                                        }
                                    }}
                                    // onClick={() => nextPage()}
                                >
                                    ...
                                </Box> 
                            </PaginationDropUp>
                        }

                        <Box
                            className={cx("",{
                            'active': parseInt(activePage) === parseInt(totalPages)
                            })}
                            sx={{
                                height:40,
                                minWidth:40,
                                pl:1,
                                pr:1,
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                                borderRadius:12,
                                color: colors.txt["primary"],
                                position: "relative",
                                cursor: "pointer",
                                userSelect:"none",
                                transition:"250ms",
                                border:"none",
                                background:"transparent",
                                fontSize:16,
                                fontFamily:"Roboto, sans-serif",

                                "& .prev_icon" : {
                                    position: "absolute",
                                    left:12
                                },

                                "&.active": {
                                    background:theme.palette.mode === "dark" ? colors.pastel.light.blue[100] : colors.primary[100],
                                    color: theme.palette.mode ==="dark" ? "#000" : null,
                                    fontWeight:700,
                                },
    
                                "&:hover" : {
                                    background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],
                                    transition:"250ms",
    
                                },
                                "&.active:hover": {
                                    background:theme.palette.mode === "dark" ? colors.pastel.light.blue[100] : colors.primary[100],
                                    color: theme.palette.mode ==="dark" ? "#000" : null,
                                    fontWeight:700,
                                },

                                "@media screen and (max-width: 47.9375em)" : {
                                    width:48,
                                    height:48
                                }
                            }}
                            onClick={(selected) => setActivePage(totalPages)}
                        >
                            {totalPages}
                        </Box> 

                        <Box
                        component={"button"}
                        type="button"
                            className="active"
                            sx={{
                                height:40,
                                width:40,
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                                borderRadius:12,
                                color: colors.txt["secondary"],
                                position: "relative",
                                cursor: "pointer",
                                userSelect:"none",
                                transition:"250ms",
                                border:"none",
                                background:"transparent",

                                "& .prev_icon" : {
                                    position: "absolute",
                                    left:12
                                },

                                "&:hover" : {
                                    background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],
                                    transition:"250ms",

                                },
                                "&:focus" : {
                                    background:"transparent"
                                },

                                "@media screen and (max-width: 47.9375em)" : {
                                    width:48,
                                    height:48
                                }
                            }}
                            onClick={() => nextPage()}
                        >
                            <ArrowForwardIos className="next_icon" fontSize="14"/>
                        </Box> 
                        
                        <Box
                            sx={{
                                ml:2
                            }}
                        >
                            <DropdownBase
                                options={datagridPageSizeOptions || []}
                                onChange={(selected) => handlePageSizeUpdate(selected)}
                                selected={0}
                                noSelection={t("no_label")} 
                                useTranslation 
                                closeOnSelection
                                disallowDeselection
                                // dropUp
                                transformOrigin={{
                                    vertical: "bottom"
                                }}
                                anchorOrigin={{
                                    vertical:"top"
                                }}
                                // searchOptions={{
                                //     enabled:true,
                                //     placeholder:"Zoek pagina"
                                // }}
                            >
                                <Box
                                        className="active"
                                        sx={{
                                            height:40,
                                            minWidth:40,
                                            display:"flex",
                                            alignItems:"center",
                                            justifyContent:"center",
                                            borderRadius:12,
                                            color:colors.txt["secondary"],
                                            position: "relative",
                                            cursor: "pointer",
                                            userSelect:"none",
                                            transition:"250ms",
                                            border:"none",
                                            background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],
                                            pl:1,
                                            pr:1,
                                            whiteSpace:"nowrap",
                                            "b" : {
                                                color: colors.txt["primary"]
                                            },
                                            
                
                                            "& .prev_icon" : {
                                                position: "absolute",
                                                left:12
                                            },
                
                                            "&:hover" : {
                                                background: colors.grey[200],
                                                transition:"250ms",
                
                                            },
                                            
                
                                            "@media screen and (max-width: 47.9375em)" : {
                                                width:48,
                                                height:48
                                            }
                                        }}
                                        // onClick={() => nextPage()}
                                    >
                                    <b>{pageSize}</b>&nbsp; {t("per_page")}
                                </Box> 
                            </DropdownBase>
                        </Box>
                    </Box>
                    {props.selectedActions}
                </Box>
            }

            {/* Mobile */}
            {rows?.length > 0 &&
                <Box 
                    className={cx('',{
                        'sidepanel':props.isSidepanelGrid,
                    })}
                    sx={{
                        position:'fixed',
                        background:"rgba(255,255,255,.75)",
                        backdropFilter:"blur(8px)",
                        boxShadow:"0px 0px 32px rgba(49,65,95,.45)",
                        borderRadius:7,
                        zIndex:200000,
                        left:"calc(50% + 270px)",
                        transform:"translateX(calc(-50% - 135px))",
                        bottom:32,
                        px:1,
                        
                        display:"flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        height:56,
                        gap:1,
                        "@media screen and (max-width: 47.9375em)" : {
                            position:"fixed",
                            bottom:72,
                            // background:theme.palette.mode === "dark" ? colors.grey[100] : colors.bg["secondary"],
                            background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                            height:48,
                            // paddingRight:2,
                            left:"50%",
                            transform:"translateX(-50%)",
                            borderRadius:8,
                            boxShadow: `0px 0px 10px 0px ${theme.palette.mode === "dark" ? colors.txt["secondary"] : "rgba(49, 65, 95, 0.35)"}`
                        },
                        "@media screen and (min-width: 47.9375em)" : {
                            display:"none"
                        }
                    }}
                >
                    <Box
                    component={"button"}
                    type="button"
                        className="active"
                        sx={{
                            height:40,
                            width:40,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            borderRadius:12,
                            color: colors.txt["secondary"],
                            position: "relative",
                            cursor: "pointer",
                            userSelect:"none",
                            transition:"250ms",
                            border:"none",
                            background:"transparent",

                            "& .prev_icon" : {
                                position: "absolute",
                                left:20
                            },

                            "&:hover" : {
                                // background: colors.grey[50],
                                background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],
                                transition:"250ms",

                            },
                            "&:focus" : {
                                background:"transparent"
                            },
                            "@media screen and (max-width: 47.9375em)" : {
                                width:48,
                                height:48
                            }
                        }}
                        onClick={() => prevPage()}
                    >
                        <ArrowBackIos className="prev_icon" fontSize="14"/>
                    </Box>
                    <PaginationDropUp
                            options={pages || []}
                            onChange={(selected) => setActivePage(selected)}
                            selected={activePage}
                            amount={totalPages}
                            noSelection={t("no_label")} 
                            useTranslation 
                            closeOnSelection
                            disallowDeselection
                            // dropUp
                            transformOrigin={{
                                vertical: "bottom"
                            }}
                            anchorOrigin={{
                                vertical:"top"
                            }}
                        >
                            <Box
                                    className="active"
                                    sx={{
                                        height:40,
                                        minWidth:40,
                                        display:"flex",
                                        alignItems:"center",
                                        justifyContent:"center",
                                        borderRadius:12,
                                        color: "#fff",
                                        position: "relative",
                                        cursor: "pointer",
                                        userSelect:"none",
                                        transition:"250ms",
                                        border:"none",
                                        background:theme.palette.mode === "dark" ? colors.grey[500] : colors.txt.primary,
                                        pl:1,
                                        pr:1,
                                        fontWeight:"bold",
                                        
            
                                        "& .prev_icon" : {
                                            position: "absolute",
                                            left:12
                                        },
            
                                        "&:hover" : {
                                            background: theme.palette.mode === "dark" ? colors.grey[400] : "#000",
                                            transition:"250ms",
            
                                        },
                                        
            
                                    }}
                                    // onClick={() => nextPage()}
                                >
                            {activePage < 10 ? "0" + activePage : activePage} 
                            </Box> 
                    </PaginationDropUp>


                    <Box
                    component={"button"}
                    type="button"
                        className="active"
                        sx={{
                            height:40,
                            width:40,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            borderRadius:12,
                            color: colors.txt["secondary"],
                            position: "relative",
                            cursor: "pointer",
                            userSelect:"none",
                            transition:"250ms",
                            border:"none",
                            background:"transparent",

                            "& .prev_icon" : {
                                position: "absolute",
                                left:12
                            },

                            "&:hover" : {
                                background: colors.grey[50],
                                transition:"250ms",

                            },
                            "&:focus" : {
                                background:"transparent"
                            },

                            "@media screen and (max-width: 47.9375em)" : {
                                width:48,
                                height:48
                            }
                        }}
                        onClick={() => nextPage()}
                    >
                        <ArrowForwardIos className="next_icon" fontSize="14"/>
                    </Box>
                </Box>
            }
            <Box
                sx={{
                    "@media screen and (min-width: 47.9375em)" : {
                        display:"none"
                    }
                }}
            >

{props.selectedActions}
            </Box>
        </Box>
    );
}



export default ShDataGrid2;

// import React, { useEffect, useRef, useState } from 'react';

export const BottomInView = (props) => {
//   const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        props.setBottomInView(entry.isIntersecting);
        // setIsInView(entry.isIntersecting);
      },
      { threshold: 1 } // Adjust this value based on how much of the item should be visible before triggering
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <Box
        ref={ref}
        sx={{
            height:32
    }}>
        {props.bottomInView ? (
            <div>Component is in view!</div>
        ) : (
            <div>Component is not in view.</div>
        )}
    </Box>
  );
};