import { Box, useTheme, Typography, InputBase } from "@mui/material";
import { useTranslation } from "react-i18next"
import { tokens } from "../../../theme";
import Text from "../../theme/text/Text";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import PopoverModal from "../../theme/Modal/PopoverModal";
import { DropdownBase } from "../../theme/dropdowns/Dropdown";
import { hexToHsl, hslToHex, hslToHsb, hslToRgb, rgbToHsl } from "../../../utils/colorConverters";

const ColorTypeMap = {
    HEX: "HEX",
    RGB: "RGB",
    HSL: "HSL",
    HSBV: "HSB/V",
}

const colorTypeArray = [ColorTypeMap.HEX,ColorTypeMap.RGB,ColorTypeMap.HSL,ColorTypeMap.HSBV];


export const SHPBColorContent = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [colorType, setColorType] = useState(0);

    const colorCanvasRef = useRef(null);
    const lightnessCanvasRef = useRef(null);
    const opacityCanvasRef = useRef(null);
    const valFieldRef = useRef(null);
    const testvalFieldRef = useRef(null);
    const opacitydivRef = useRef(null);
  
    const [currentHue, setCurrentHue] = useState(0);
    const [currentSaturation, setCurrentSaturation] = useState(100);
    const [currentLightness, setCurrentLightness] = useState(50);
    const [currentOpacity, setCurrentOpacity] = useState(1);
  
    const [isDraggingColor, setIsDraggingColor] = useState(false);
    const [isDraggingLightness, setIsDraggingLightness] = useState(false);
    const [isDraggingOpacity, setIsDraggingOpacity] = useState(false);
  
    const [lightnessPuckPosition, setLightnessPuckPosition] = useState({ x: 0, y: 0 });
  
    const puckRadius = 8;
  
    const drawColorPalette = () => {
      const colorCanvas = colorCanvasRef.current.getContext('2d');
      const width = colorCanvasRef.current.width;
      const height = colorCanvasRef.current.height;
      
      for (let i = 0; i <= height; i++) {
        colorCanvas.fillStyle = `hsl(${(i * 360 / height)}, 100%, 50%)`;
        colorCanvas.fillRect(0, i, width, 1);
      }
      drawColorPuck();
    };
  
    const drawColorPuck = () => {
      const colorCanvas = colorCanvasRef.current.getContext('2d');
      const yPos = (currentHue / 360) * colorCanvasRef.current.height;
      colorCanvas.beginPath();
      colorCanvas.arc(colorCanvasRef.current.width / 2, yPos, puckRadius, 0, Math.PI * 2);
      colorCanvas.fillStyle = `hsl(${currentHue}, 100%, 50%)`;
      colorCanvas.fill();
      colorCanvas.lineWidth = 4;
      colorCanvas.strokeStyle = 'white';
      colorCanvas.stroke();
    };
  
    const drawLightnessControl = () => {
      const lightnessCanvas = lightnessCanvasRef.current.getContext('2d');
      const width = lightnessCanvasRef.current.width;
      const height = lightnessCanvasRef.current.height;
      lightnessCanvas.clearRect(0, 0, width, height);
  
      const colorGradient = lightnessCanvas.createLinearGradient(0, 0, width, 0);
      colorGradient.addColorStop(0, 'white');
      colorGradient.addColorStop(0.05, 'white');
      colorGradient.addColorStop(0.95, `hsl(${currentHue}, 100%, 50%)`);
      colorGradient.addColorStop(1, `hsl(${currentHue}, 100%, 50%)`);
      lightnessCanvas.fillStyle = colorGradient;
      lightnessCanvas.fillRect(0, 0, width, height);
  
      const blacknessGradient = lightnessCanvas.createLinearGradient(0, 0, 0, height);
      blacknessGradient.addColorStop(0, 'rgba(0, 0, 0, 0)');
      blacknessGradient.addColorStop(0.05, 'rgba(0, 0, 0, 0)');
      blacknessGradient.addColorStop(0.95, 'black');
      blacknessGradient.addColorStop(1, 'black');
      lightnessCanvas.fillStyle = blacknessGradient;
      lightnessCanvas.fillRect(0, 0, width, height);
      
      drawLightnessPuck(lightnessPuckPosition.x, lightnessPuckPosition.y);

        // Now request the next animation frame
        // requestAnimationFrame(() => drawLightnessPuck(lightnessPuckPosition.x, lightnessPuckPosition.y));  // Example of continuous movement (incrementing xPos)

    };
  
    
    const drawLightnessPuck = (xPos, yPos) => {
        const lightnessCanvas = lightnessCanvasRef.current.getContext('2d');
        lightnessCanvas.beginPath();
        lightnessCanvas.arc(xPos, yPos, puckRadius, 0, Math.PI * 2);
        lightnessCanvas.lineWidth = 4;
        lightnessCanvas.strokeStyle = 'white';
        lightnessCanvas.stroke();
    };
    
  
    const drawOpacityControl = () => {
      const opacityCanvas = opacityCanvasRef.current.getContext('2d');
      const width = opacityCanvasRef.current.width;
      const height = opacityCanvasRef.current.height;
      opacityCanvas.clearRect(0, 0, width, height);
  
      drawRasterPattern(width, height);
  
      const baseColor = `hsl(${currentHue}, ${currentSaturation}%, ${currentLightness}%)`;
      const opacityGradient = opacityCanvas.createLinearGradient(0, 0, width, 0);
      opacityGradient.addColorStop(0, `${baseColor.slice(0, -1)}, 0)`);
      opacityGradient.addColorStop(1, baseColor);
      opacityCanvas.fillStyle = opacityGradient;
      opacityCanvas.fillRect(0, 0, width, height);
  
      drawOpacityPuck();
    };
  
    const drawRasterPattern = (width, height) => {
      const rasterSize = height / 2;
      const opacityCanvas = opacityCanvasRef.current.getContext('2d');
      for (let x = 0; x < width; x += rasterSize) {
        for (let y = 0; y < height; y += rasterSize) {
          opacityCanvas.fillStyle = (x / rasterSize + y / rasterSize) % 2 === 0 ? '#DDD' : '#FFF';
          opacityCanvas.fillRect(x, y, rasterSize, rasterSize);
        }
      }
    };
  
    const drawOpacityPuck = () => {
      const opacityCanvas = opacityCanvasRef.current.getContext('2d');
      const xPos = currentOpacity * opacityCanvasRef.current.width;
      opacityCanvas.beginPath();
      opacityCanvas.arc(xPos, opacityCanvasRef.current.height / 2, puckRadius, 0, Math.PI * 2);
      opacityCanvas.fillStyle = `hsla(${currentHue}, ${currentSaturation}%, ${currentLightness}%, ${currentOpacity})`;
      opacityCanvas.fill();
      opacityCanvas.lineWidth = 4;
      opacityCanvas.strokeStyle = 'white';
      opacityCanvas.stroke();
    };
  
    const redrawCanvases = () => {
        drawColorPalette();
        drawLightnessControl();
        drawOpacityControl();
    };
  
    const startDragging = (event, canvasType) => {
      if (canvasType === 'color') setIsDraggingColor(true);
      else if (canvasType === 'lightness') setIsDraggingLightness(true);
      else if (canvasType === 'opacity') setIsDraggingOpacity(true);
      updateSelection(event, canvasType);
    };
  
    const stopDragging = () => {
      setIsDraggingColor(false);
      setIsDraggingLightness(false);
      setIsDraggingOpacity(false);
    };
  
    const drag = (event, canvasType) => {
      if ((isDraggingColor && canvasType === 'color') ||
        (isDraggingLightness && canvasType === 'lightness') ||
        (isDraggingOpacity && canvasType === 'opacity')) {
        updateSelection(event, canvasType);
        redrawCanvases();  // Redrawing all canvases after drag
      }
    };
  
    const updateSelection = (event, canvasType) => {
      if (canvasType === 'color') {
        handleColorSelection(event);
      } else if (canvasType === 'lightness') {
        handleLightnessSelection(event);
      } else if (canvasType === 'opacity') {
        handleOpacitySelection(event);
      }
    };
  
    const handleColorSelection = (event) => {
      const mousePos = getMousePos(colorCanvasRef.current, event);

      setCurrentHue(Math.round(Math.max(0, Math.min(360, Math.round((mousePos.y / colorCanvasRef.current.height) * 360)))));

      // Update lightness and opacity controls based on the new hue
      drawLightnessControl();
      drawOpacityControl();
    };
  
    const handleLightnessSelection = (event) => {
      const mousePos = getMousePos(lightnessCanvasRef.current, event);
  
      if (mousePos.x >= 0 && mousePos.x <= lightnessCanvasRef.current.width &&
          mousePos.y >= 0 && mousePos.y <= lightnessCanvasRef.current.height) {
          const lightnessCanvas = lightnessCanvasRef.current.getContext('2d');
          const imageData = lightnessCanvas.getImageData(mousePos.x, mousePos.y, 1, 1).data;
          const hsl = rgbToHsl(imageData[0], imageData[1], imageData[2]);
  
          // Preserve hue while changing saturation and lightness
          setCurrentSaturation(Math.round(hsl[1]));
          setCurrentLightness(Math.round(hsl[2]));
          
          setLightnessPuckPosition({ x: mousePos.x, y: mousePos.y });
          drawOpacityControl();
      } else {
            let xPos = Math.max(0, Math.min(lightnessCanvasRef.current.width-4, mousePos.x));
            let yPos = Math.max(0, Math.min(lightnessCanvasRef.current.height, mousePos.y));

            const lightnessCanvas = lightnessCanvasRef.current.getContext('2d');
            const imageData = lightnessCanvas.getImageData(xPos, yPos, 1, 1).data;
   
            
            let hsl = rgbToHsl(imageData[0], imageData[1], imageData[2]);

            setCurrentSaturation(Math.round(hsl[1]));
            setCurrentLightness(Math.round(hsl[2]));
            
            setLightnessPuckPosition({ x: xPos, y: yPos });
            drawOpacityControl();

        }
    };
  
    const handleOpacitySelection = (event) => {
      const mousePos = getMousePos(opacityCanvasRef.current, event);
      setCurrentOpacity(Math.max(0, Math.min(1, mousePos.x / opacityCanvasRef.current.width)));
    };
  
    const getMousePos = (canvas, event) => {
      const rect = canvas.getBoundingClientRect();
      return { x: event.clientX - rect.left, y: event.clientY - rect.top };
    };

    function hslToCanvasCoords(h, s, l, canvasWidth, canvasHeight) {
        // Normalize HSL values to be in range 0-1
        const normalizedH = (h % 360) / 360;
        const normalizedS = s / 100;
        const normalizedL = l / 100;
      
        // Directly map hue to the X-axis (horizontal position)
        const x = Math.floor(normalizedH * canvasWidth);
      
        // Saturation affects Y position in terms of intensity (top to bottom)
        const y = Math.floor((1 - normalizedS) * (canvasHeight * 0.5) + (normalizedL * canvasHeight * 0.5));
      
        return { x, y };
    }

    function findHSLColorPosition(canvas, targetHSL) {
        const { h, s, l } = targetHSL;
      
        // Map the target HSL value to canvas coordinates
        const { x, y } = hslToCanvasCoords(h, s, l, canvas.width, canvas.height);
      
        // Return the calculated position directly
        return { x, y };
      }


    useEffect(() => {
        const handleMouseMove = (e) => {
            drag(e, 'color');
            drag(e, 'lightness');
            drag(e, 'opacity');
        };
    
        const handleTouchMove = (e) => {
            drag(e.touches[0], 'color');
            drag(e.touches[0], 'lightness');
            drag(e.touches[0], 'opacity');
        };
    
        const handleMouseUp = () => stopDragging();
        const handleTouchEnd = () => stopDragging();
    
        // Listen for mouse/touch events globally
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('touchmove', handleTouchMove);
        window.addEventListener('mouseup', handleMouseUp);
        window.addEventListener('touchend', handleTouchEnd);
    
        // Cleanup on unmount or state change
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('touchend', handleTouchEnd);
        };
    }, []);
  

    
  // Check if refs are available after the popover is opened
    const [isRendered, setIsRendered] = useState(false);
    const [hasBeenSet, setHasBeenSet] = useState(false);
    useEffect(() => {
        if (props.open) {
            // Wait for the next render cycle to check if refs are available
            const checkRefs = () => {
                if (colorCanvasRef.current && lightnessCanvasRef.current) {
                    setIsRendered(true);
                }
            };
            if(props.value) {
                const val = hexToHsl(props.value);
                setCurrentHue(val[0])
                setCurrentSaturation(val[1])
                setCurrentLightness(val[2])
            }

            // Run the check after the popover opens
            const timeout = setTimeout(checkRefs, 1); // You can adjust the timeout if needed
            return () => clearTimeout(timeout); // Clean up on component unmount
        } else {
            setIsRendered(false); // Reset if popover is closed
            setHasBeenSet(false);
        }
    }, [props.open,props.value]);
    

    useEffect(() => {
        if (isRendered) {
            if(lightnessCanvasRef.current) {
                
                const lightnessCanvas = lightnessCanvasRef.current;
                const container = lightnessCanvas.parentElement;

                lightnessCanvas.width = container.clientWidth-48;
                lightnessCanvas.height = 210; // Set height directly or calculate if needed
                
                const {x,y} = findHSLColorPosition(lightnessCanvas, {h:currentHue,s:currentSaturation,l:currentLightness});
                setLightnessPuckPosition({x:x,y:y});
                setHasBeenSet(true);
            }
            if(opacityCanvasRef.current) {
                const opacityCanvas = opacityCanvasRef.current;
                console.log(opacityCanvas.width);
                const container = opacityCanvas.parentElement;

                opacityCanvas.width = container.clientWidth-48;
                // lightnessCanvas.height = 210; // Set height directly or calculate if needed

            }
            // redrawCanvases();
        }
    }, [isRendered]);
    

    useEffect(() => {
        if (hasBeenSet) {
            
            redrawCanvases();
        }
    }, [hasBeenSet]);

    return (
        <PopoverModal
            // onMouseEnter={handlePopoverEntered}
            {...props}
        >
            <Box className="color-picker"
                sx={{
                    display:"flex",
                    flexDirection:"column",
                    gap:1.5,
                    py:3,
                    background:"white",
                }}
            >
                <Box 
                    sx={{
                        display:"flex",
                        justifyContent:"flex-start",
                        alignItems:"stretch",
                        gap:1.5,
                        px:3,
                        // height:250,

                        "& .hue-canvas" : {
                            width:12,
                            overflow:"hidden",
                            borderRadius:2,
                            flexShrink:0,
                        },
                        "& .color-canvas" : {
                            flex:1,
                            // overflow:"hidden",
                            borderRadius:2,
                            // maxWidth:320,
                        }
                    }}
                >
                    <canvas
                        className="hue-canvas"
                        ref={colorCanvasRef}
                        height="210"
                        width="12"
                        onMouseDown={(e) => startDragging(e, 'color')}
                        onTouchStart={(e) => startDragging(e.touches[0], 'color')}
                        onMouseMove={(e) => drag(e, 'color')}
                        onTouchMove={(e) => drag(e.touches[0], 'color')}
                    
                    />
                    <canvas
                        className="color-canvas"
                        ref={lightnessCanvasRef}
                        // width="300"
                        height="210"
                        onMouseDown={(e) => startDragging(e, 'lightness')}
                        onTouchStart={(e) => startDragging(e.touches[0], 'lightness')}
                        onMouseMove={(e) => drag(e, 'lightness')}
                        onTouchMove={(e) => drag(e.touches[0], 'lightness')}
                    
                    />
                </Box>
                <Box
                    sx={{
                        height:18,
                        px:3,
                        "& canvas" : {
                            width:1,
                            // ml:3,
                            overflow:"hidden",
                            borderRadius:2,
                        }
                    }}
                >
                    <canvas
                        ref={opacityCanvasRef}
                        // width="300"
                        height="12"

                        onMouseDown={(e) => startDragging(e, 'opacity')}
                        onTouchStart={(e) => startDragging(e.touches[0], 'opacity')}
                        onMouseMove={(e) => drag(e, 'opacity')}
                        onTouchMove={(e) => drag(e.touches[0], 'opacity')}
                    
                    />
                </Box>

                <Box
                    sx={{
                        mx:3,
                        mt:1.5,
                        height:32,
                        background: colors.bg['tertiary'],
                        borderRadius:5,
                        p:0.5,
                        display:"flex",
                        justifyContent:"space-between",
                    }}
                >
                    <DropdownBase
                        key={colorType}
                        stopPropagation
                        displayName={"name"}
                        selected={colorType}
                        onChange={(selected) => {
                            console.log(selected);
                            setColorType(selected)
                        }}
                        options={colorTypeArray} // Only unlink when no stock on location
                        closeOnSelection
                        styles={{
                            height: 32
                        }}
                        disallowDeselection
                    >
                        <Box
                            sx={{
                                // px:1.5,
                                width:75,
                                textAlign:"center",
                                background: colors.grey[200],
                                height:24,
                                borderRadius:3,
                                color: colors.txt["primary"],
                                fontWeight:500,
                                fontFamily:"Roboto,sans-serif",
                                cursor:"pointer"
                            }}
                        >
                            {colorTypeArray[colorType]}
                        </Box>
                    </DropdownBase>
                    <Box
                        sx={{
                            marginRight:"auto",
                            flex:1,
                            pl:1.5,
                            overflow:"hidden",
                            whiteSpace:"nowrap",
                            wordBreak:"keep-all",
                            textOverflow:"ellipsis",
                            maxWidth:220,
                            
                            // background:"blue"
                        }}
                    >

                        {colorTypeArray[colorType] === ColorTypeMap.HEX && hslToHex(currentHue,currentSaturation,currentLightness)}
                        {colorTypeArray[colorType] === ColorTypeMap.HSL && `${currentHue}, ${currentSaturation}, ${currentLightness}`}

                        {colorTypeArray[colorType] === ColorTypeMap.RGB && (
                            (() => {
                                const rgb = hslToRgb(currentHue, currentSaturation, currentLightness);
                                return `${rgb[0]}, ${rgb[1]}, ${rgb[2]}`; // You can replace this with whatever you want to render
                            })()
                        )}

                        {colorTypeArray[colorType] === ColorTypeMap.HSBV && (
                            (() => {
                                const rgb = hslToHsb(currentHue, currentSaturation, currentLightness);
                                return `${rgb[0]}, ${rgb[1]}, ${rgb[2]}`; // You can replace this with whatever you want to render
                            })()
                        )}
                    </Box>
                    <Box>
                        <InputBase
                            sx={{
                                maxWidth:50,
                                // background:"orange",
                            }}
                            value={currentOpacity*100}
                        />
                        %
                    </Box>
                    
                </Box>
            </Box>
        </PopoverModal>
    );
};
  

export const SHPBColor = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const filterPopoverOpen = Boolean(filterAnchorEl);
    const filterPopoverId = filterPopoverOpen ? 'simple-popover' : undefined;

    const handleFilterPopoverClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };
    const handleFilterPopoverClose = () => {
        setFilterAnchorEl(null);
        // setExportAnchorEl(null);
    };
    return (
        <Box>
            <Box
                sx={{
                    display:"flex",
                    height:40,
                    background: colors.bg['tertiary'],
                }}
            >
                <InputBase sx={{flex:1}}/>
                <Box
                    onClick={handleFilterPopoverClick}
                    sx={{
                        height:32,
                        width:32,
                        flexShrink:0,
                        background:"orange",
                    }}
                >

                </Box>
            </Box>
            <SHPBColorContent
                    value={props.value}
                    setValue={props.setValue}
                    id={filterPopoverId}
                    open={filterPopoverOpen}
                    anchorEl={filterAnchorEl}
                    onClose={handleFilterPopoverClose}
            
            />
        </Box>
    )
}
// export default SHPBColor;
