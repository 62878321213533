import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
// import { tokens } from "../../../../theme";
import Text from "../../../../../../../components/theme/text/Text";
import { tokens } from "../../../../../../../theme";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../../../components/theme/buttons/Shbutton";

import { GridCheckIcon } from "@mui/x-data-grid";
import { ClearIcon } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import MpAddStream from "../../../../../../../components/global/ModalsProgression/MpAddStream/MpAddStream";
import ShDataGrid2 from "../../../../../../../components/theme/datagrid2/ShDataGrid2";
import ShLabel from "../../../../../../../components/theme/label/ShLabel";
import ShLabelSkeleton from "../../../../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetStreamsQuery } from "../../../../../../../newapi/global/stream/streamSlice";

import { QuestionMarkOutlined } from "@mui/icons-material";
import WarehousePicker from '../../../../../../../components/global/WarehousePicker';
import { WarehouseStreamCard } from "../../../../../../../components/global/cards/settings/StreamCard";
import SidepanelLoader from "../../../../../../../components/theme/sidepanel2/SidepanelLoader";
import { ReactComponent as BolLogo } from '../../../../../../../styles/svg/bol.svg';
import { ReactComponent as DhlLogo } from '../../../../../../../styles/svg/dhl.svg';
import { ReactComponent as DpdLogo } from '../../../../../../../styles/svg/dpd.svg';
import { ReactComponent as PostNLLogo } from '../../../../../../../styles/svg/postnl.svg';

const SpStreamDetails = SidepanelLoader(() => import("../../../../../../../components/global/Sidepanels/SpStreamDetails/SpStreamDetails"));

const GridActions = (props, i18n) => {
    const theme = useTheme();
    
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("add_stream")}</Shbutton>
        </ButtonGroup>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    

};


const All = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended,setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();


    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

    const [streamId, setStreamId] = useState();
    const streamIdRef = useRef(streamId);
    
    const getStreamDetails = (params) => {
        if (!params) return;
    
        const newstreamId = parseInt(params.row.id);
        // Only update state if the streamId has actually changed
        if (streamIdRef.current !== newstreamId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setStreamId(newstreamId);
            streamIdRef.current = newstreamId; // Update the ref to the new value
        }
        setIsExtended(true);
    };
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            topRight:<WarehousePicker desktop={false}/>,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[props.warehouseModal]);
    
    const columns = [
        {field: "name", headerName: t("stream") , flex:1, cellClassName: "stream", renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                // <Box sx={{display:"flex", flexDirection:"column", gap:1}}>
                
                // <Text>{cellValues.row.shortName}</Text>
                // </Box>
                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
            )
        }},
        {field: "state", headerName: t("state"), flex:1, renderCell: (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShLabelSkeleton/>
            }
            return (
               
                <Box>
                    <Text>
                        {cellValues.value == 'active' ? (
                             <ShLabel palette="normal"  size={32} fitted variant={"green"}>
                             <Text variant="green">{t("active")}</Text>
                         </ShLabel>
                        ) : (
                            <ShLabel  size={32} fitted variant={"grey"}>
                            <Text variant="grey">{t("inactive")}</Text>
                        </ShLabel>
                            
                        )}
                    </Text>
                </Box>
            )
        }},
        {field: "warehouse", headerName: t("warehouse"), flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Text >{cellValues.row.warehouse?.name}</Text>
            )
        }},
        // {field: "maxLength", headerName: t("size"), flex:2, renderCell : (cellValues) => {
        //     if(cellValues.row.skeleton) {
        //         return <ShDoubleTextSkeleton/>
        //     }
        //     return (
        //     <Box sx={{display:"flex", flexDirection:"column", gap:1}}>
        //         <Box>
        //             <Text light>{t("min")}: </Text>
        //             <Text sx={{marginLeft:1}}>{`${cellValues.row.minWidth ?? 0}mm x ${cellValues.row.minLength ?? 0}mm x ${cellValues.row.minHeight ?? 0}mm (${cellValues.row.minVolume ?? 0} mm3)`}</Text>
        //         </Box>
        //         <Box>
        //             <Text light>{t("max")}: </Text>
        //             <Text sx={{marginLeft:1}}>{`${cellValues.row.maxWidth ?? 0}mm x ${cellValues.row.maxLength ?? 0}mm x ${cellValues.row.maxHeight ?? 0}mm (${cellValues.row.maxVolume ?? 0} mm3)`}</Text>
        //         </Box>
        //     </Box>
        //     )
        // }},
        // {field: "minWeight", headerName: t("weight"), flex: 1, renderCell: (cellValues) => {
        //     if(cellValues.row.skeleton) {
        //         return <ShDoubleTextSkeleton/>
        //     }
        //     return (
        //         <Box sx={{display:"flex", flexDirection:"column", gap:1}}>
        //         <Box>
        //             <Text light>{t("min")}: </Text>
        //             <Text sx={{marginLeft:1}}>{cellValues.value ? `${cellValues.value} ${t("kg")}` : "-"}</Text>
        //         </Box>
        //         <Box>
        //             <Text light>{t("max")}: </Text>
        //             <Text sx={{marginLeft:1}}>{cellValues.row.maxWeight ? `${cellValues.row.maxWeight} ${t("kg")}` : "-"}</Text>
        //         </Box>
        //     </Box>
        //     )
        // }},
        {field: "deliverytimePriority", headerName: t("deliverytimepriority"), flex:1, renderCell: (cellValues) => {
            return (
                <Box>
                    <Text sx={{color:cellValues.value ? "green" : "red"}}>
                        {cellValues.value ? (
                            <GridCheckIcon />
                        ) : (
                            <ClearIcon/>
                        )}
                    </Text>
                    {/* <WarehousePic */}
                </Box>
            )
        }},
        {field: "pickingprocesstype", headerName: t("pickingprocesstype"), flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Text>{t(cellValues.value)}</Text>
            )
        }},
        {field: "shipper", headerName: t("shipper"), flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box sx={{display:"flex", flexDirection:"row", gap:1}}>
                    <Box sx={{ paddingRight: '10px' }}>
                        {cellValues.value?.type === "postnl" ? <PostNLLogo width={30} height={30}/> : null}
                        {cellValues.value?.type === "dhl" ? <DhlLogo  width={30} height={30}/> : null}
                        {cellValues.value?.type === "dpd" ? <DpdLogo width={30} height={30}/> : null}
                        {cellValues.value?.type === "bol" ? <BolLogo width={30} height={30}/> : null}
                        {cellValues.value?.type === "other" ? <QuestionMarkOutlined width={30} height={30}/> : null}
                    </Box>
                    <Box sx={{display:"flex", flexDirection:"column", gap:1}}>
                        <Text>{ cellValues.value?.name}</Text>
                        {cellValues.row.streamrules?.length > 0 && (
                            <Text light>+ { cellValues.row.streamrules.length} {t("rules")}</Text>
                        )}
                    </Box>    
                </Box>            
            )
        }},

        {field: "reachType", headerName: t("reachtype"), flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Text>{ cellValues.value?.description}</Text>
            )
        }},
        // {field: "fitsPost", headerName: "Fits in dutch postorder", flex:1, renderCell : (cellValues) => {
        //     return (
        //         cellValues.value === "true" ?
        //             <Text variant="green"><CheckOutlinedIcon fontSize="small"/></Text>:
        //         cellValues.value === "false" ?
        //             <Text variant="red"><CloseOutlinedIcon fontSize="Small" /></Text> 
        //         :
        //             <Text variant="orange"><HorizontalRuleOutlinedIcon fontSize="Small"/></Text>
        //     )
        // }},

        // {field: "channels", headerName: "Channels", flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
        //     )
        // }},
        // {field: "weight", headerName: "Weight including label", flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text>{cellValues.value}</Text>
        //     )
        // }},
        // {field: "length", headerName: t("dimensions_lwh"), flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text>{`${cellValues.row.length} x ${cellValues.row.width} x ${cellValues.row.height}`}</Text>
        //     )
        // }},
        // {field: "barcodes", headerName: "Nr. of barcodes", flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text>{cellValues.value}</Text>
        //     )
        // }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
               return (
                    <WarehouseStreamCard
                        skeleton={cellValues.skeleton ?? false}
                        {...cellValues.row}
                        onClick={() => getStreamDetails(cellValues)}
                    />
                    // <Box>
                    //     <Text>{cellValues.row.name}</Text>
                    // </Box>
               )
            }
        }
    ];
    
    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetStreamsQuery}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t("overview")}
                gridOptions={gridOptions} 
                columns={columns}
                sortModel={{field: columns[0].field,type:"ASC"}}
                
                onRowClick={getStreamDetails}>
            </ShDataGrid2>

            <MpAddStream onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>

            {spLoading && 
            <SpStreamDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                streamId={streamId}
                // data={streamData} 
                /> 
            }
        </Box>
    )
};

export default All;