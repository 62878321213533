export const hslToRgb = (h, s, l) => {
    s /= 100;
    l /= 100;
  
    let c = (1 - Math.abs(2 * l - 1)) * s;
    let x = c * (1 - Math.abs((h / 60) % 2 - 1));
    let m = l - c / 2;
  
    let r, g, b;
    if (h >= 0 && h < 60) {
        r = c;
        g = x; b = 0;
    } else if (h >= 60 && h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (h >= 120 && h < 180) {
        r = 0; 
        g = c; 
        b = x;
    } else if (h >= 180 && h < 240) {
        r = 0; 
        g = x;
        b = c;
    } else if (h >= 240 && h < 300) {
        r = x; 
        g = 0; 
        b = c;
    } else {
        r = c; 
        g = 0; 
        b = x;
    }
  
    // Convert to RGB and apply the m value to each channel
    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);
  
    return [r, g, b]; 
};
export const hslToHsb = (h, s, l) => {
    s /= 100;
    l /= 100;
  
    let brightness = l + s * Math.min(l, 1 - l);
    let saturation = 0;
  
    if (brightness !== 0) {
        saturation = 2 * (1 - l / brightness);
    }
  
    return [Math.round(h), Math.round(saturation * 100), Math.round(brightness * 100)];
};

export const hslToHex = (h, s, l) => {
    const [r, g, b] = hslToRgb(h, s, l);
  
    // Convert each RGB value to a two-digit hex
    const rgbToHex = (rgb) => {
        return rgb.map(value => value.toString(16).padStart(2, '0')).join('');
    };
  
    return `#${rgbToHex([r, g, b])}`;
};

export const hsbToHsl = (h, s, b) => {
    // Convert saturation and brightness to their 0-1 scale
    s /= 100;
    b /= 100;
  
    let l = (2 - s) * b / 2;  // Calculate lightness
    let newSaturation = s * b;
  
    // If the lightness is not 0 or 1, adjust the saturation
    if (l !== 0 && l !== 1) {
      newSaturation = (2 - l * 2) * newSaturation / (l * 2);
    }
  
    return [
      h, // Hue stays the same
      newSaturation * 100, // Saturation in HSL (0 to 100)
      l * 100 // Lightness in HSL (0 to 100)
    ];
  };

export const rgbToHsl = (r, g, b) => {
    r /= 255; 
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0;
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }
    return [h * 360, s * 100, l * 100];
};


// HEX to RGBA conversion function
export const hexToRgb = (hex) => {
    // Remove the leading '#' if present
    hex = hex.replace('#', '');
    
    // Parse the r, g, b values from the hex string
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    return [r, g, b];
};
// Function to validate HEX color and set default if invalid
export const isValidHex = (hex) => {
    // Remove the leading '#' if present
    hex = hex.replace('#', '');

    // Check if it's either 3 or 6 characters long and contains valid hex characters
    const hexPattern = /^[0-9A-Fa-f]{3,6}$/;

    return hexPattern.test(hex);
};

// HEX to HSL conversion using your rgbaToHsl function
export const hexToHsl = (hex) => {
    if (!isValidHex(hex)) {
        hex = '#EEEEEE';  // Default to #EEEEEE if invalid
    }
    const [r, g, b] = hexToRgb(hex);  // Convert HEX to RGBA
    return rgbToHsl(r, g, b); // Call rgbaToHsl with RGBA values
};

