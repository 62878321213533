// UserPrivileges
export const UserPrivileges = {
    ACCOUNTINGSOFTWARE_MANAGE: "accountingsoftware_manage",
    BACKOFFICE_GENERAL: "backoffice_general",
    CARRIER_MANAGE: "carrier_manage",
    COUNTINGSTRATEGY_READ: "countingstrategy_read",
    COUNTINGSTRATEGY_MANAGE: "countingstrategy_manage",
    INBOUND_READ: "inbound_read",
    INBOUND_MANAGE: "inbound_manage",
    INVOICEPRODUCT_MANAGE: "invoiceproduct_manage",
    INVOICEPRODUCT_READ: "invoiceproduct_read",
    INVOICE_MANAGE: "invoice_manage",
    INVOICE_READ: "invoice_read",
    ECHECK: "echeck",
    OUTBOUND_READ: "outbound_read",
    OUTBOUND_MANAGE: "outbound_manage",
    PALLETSPOT_READ: "palletspot_read",
    PICKINGJOB_MANAGE: "pickingjob_manage",
    PICKINGJOB_READ: "pickingjob_read",
    PRICEINCREASE_MANAGE: "priceincrease_manage",
    PRICEINCREASE_READ: "priceincrease_read",
    PRODUCT_IMPORT: "product_import",
    PRODUCT_MANAGE: "product_manage",
    PRODUCT_READ: "product_read",
    RELATION_MANAGE: "relation_manage",
    RELATION_READ: "relation_read",
    RETURN_READ: "return_read",
    RETURN_MANAGE: "return_manage",
    REPLENISH: "replenish",
    RP_ACCESS_READ: "rp_access_read",
    RP_ACCESS_MANAGE: "rp_access_manage",
    RP_INBOUND_READ: "rp_inbound_read",
    RP_INBOUND_MANAGE: "rp_inbound_manage",
    RP_INVOICE_READ: "rp_invoice_read",
    RP_ORDER_READ: "rp_order_read",
    RP_ORDER_MANAGE: "rp_order_manage",
    RP_PRODUCT_IMPORT: "rp_product_import",
    RP_PRODUCT_READ: "rp_product_read",
    RP_PRODUCT_MANAGE: "rp_product_manage",
    RP_RETURN_READ: "rp_return_read",
    RP_RETURN_MANAGE: "rp_return_manage",
    RP_SUPPLIER_READ: "rp_supplier_read",
    RP_SUPPLIER_MANAGE: "rp_supplier_manage",
    RP_WEBSHOPCREDENTIALS_MANAGE: "rp_webshopcredentials_manage",
    SETTINGS_ALL: "settings_all",
    SETTINGS_GENERAL: "settings_general",
    SETTINGS_MENU: "settings_menu",
    SETTINGS_USERPRIVILEGES: "settings_userprivileges",
    SHIPPER_MANAGE: "shipper_manage",
    SHIPPER_READ: "shipper_read",
    STOCK_READ: "stock_read",
    STOCK_MANAGE: "stock_manage",
    STOCK_TRANSFER: "stock_transfer",
    SUPPLIER_READ: "supplier_read",
    SUPPLIER_MANAGE: "supplier_manage",
    WAREHOUSE_READ: "warehouse_read",
    WAREHOUSE_MANAGE: "warehouse_manage",
    WAREHOUSELOCATION_READ: "warehouselocation_read",
    WAREHOUSELOCATION_MANAGE: "warehouselocation_manage",
    WEBSHOPCREDENTIALS_RELATION: "webshopcredentials_relation",
    WEBSHOPCREDENTIALS_MANAGE: "webshopcredentials_manage",
    PRINTER_MANAGE: "printer_manage",
    PRINTER_READ: "printer_read",
    WORKFLOW_MANAGE: "workflow_manage",
    WORKFLOW_READ: "workflow_read",
    WORKFLOWTEMPLATE_MANAGE: "workflowtemplate_manage",
    WORKFLOWTEMPLATE_READ: "workflowtemplate_read",
    WORKSPACE_MANAGE: "workspace_manage",
    WORKSPACE_READ: "workspace_read"
};